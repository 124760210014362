import api from "@/libs/axios";
import { AnalyseTactique } from "../models/analyseTactique/analyseTactique";
import { PaginatedList } from "../models/common/paginatedList";
import { Contact } from "../models/contacts/contact";
import { Contrat, UpdateContratModel } from "../models/contrats/contrat";
import { Fournisseur } from "../models/fournisseurs/fournisseur";

const resource = "Fournisseurs";

export default class FournisseursRessources {
  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    search?: string,
    pertinentOnly?: boolean,
  ): Promise<PaginatedList<Fournisseur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());
    if (pertinentOnly) params.append("PertinentOnly", pertinentOnly.toString());

    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }

  public async getById(id: string): Promise<Fournisseur> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async post(data: Fournisseur): Promise<Fournisseur> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async update(id: string, data: Fournisseur): Promise<Fournisseur> {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async delete(id: string): Promise<Fournisseur> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async getListContactsByFournisseurId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    search?: string
  ): Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contacts`, {
      params: params,
    });
    return response.data;
  }

  public async createContactOnFournisseur(
    id: string,
    data: Contact
  ): Promise<Contact> {
    const response = await api.post(`${resource}/${id}/contacts`, data);
    return response.data;
  }

  public async deleteContactFournisseur(
    id: string,
    contactId: string
  ): Promise<Contact> {
    const response = await api.delete(
      `${resource}/${id}/contacts/${contactId}`
    );
    return response.data;
  }

  public async getContactById(id: string, contactId: string): Promise<Contact> {
    const response = await api.get(`${resource}/${id}/contacts/${contactId}`);
    return response.data;
  }

  public async updateContactFournisseur(
    id: string,
    contactId: string,
    data: Contact
  ): Promise<Contact> {
    const response = await api.put(
      `${resource}/${id}/contacts/${contactId}`,
      data
    );
    return response.data;
  }

  public async getListContratsByFournisseurId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    orderBy?: string,
    orderByDescending?: boolean
  ): Promise<PaginatedList<Contrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());
    
    const response = await api.get(`${resource}/${id}/contrats`, {
      params: params,
    });
    return response.data;
  }

  public async createContratOnFournisseur(
    id: string,
    data: Contrat
  ): Promise<Contrat> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    if (data.typeContrat) formData.append("typeContrat", data.typeContrat);
    if (data.nom) formData.append("nom", data.nom);
    formData.append(
      "avenant",
      data.avenant ? data.avenant.toString() : false.toString()
    );
    if (data.dateDemarrage)
      formData.append("dateDemarrage", data.dateDemarrage);
    if (data.dateExpiration)
      formData.append("dateExpiration", data.dateExpiration);

    const response = await api.post(`${resource}/${id}/contrats`, formData);
    return response.data;
  }

  public async deleteContratFournisseur(
    id: string,
    contratId: string
  ): Promise<Contrat> {
    const response = await api.delete(
      `${resource}/${id}/contrats/${contratId}`
    );
    return response.data;
  }

  public async getContratById(id: string, contactId: string): Promise<Contrat> {
    const response = await api.get(`${resource}/${id}/contrats/${contactId}`);
    return response.data;
  }

  public async updateContratFournisseur(
    id: string,
    contratId: string,
    data: UpdateContratModel
  ): Promise<Contrat> {
    const response = await api.put(
      `${resource}/${id}/contrats/${contratId}`,
      data
    );
    return response.data;
  }

  public async downloadContrat(
    fournisseurId: string,
    contratId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${fournisseurId}/contrats/${contratId}/download`
    );
    return response.data;
  }

  public async getListAnalysesTactiqueByFournisseurId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    search?: string
  ): Promise<PaginatedList<AnalyseTactique>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/analysesTactique`, {
      params: params,
    });
    return response.data;
  }

  public async postAnalyseTactique(
    id: string,
    data: AnalyseTactique
  ): Promise<AnalyseTactique> {
    const response = await api.post(`${resource}/${id}/analysesTactique`, data);
    return response.data;
  }
}
