export default [
  
  {
    path: '/types-partenaires-fastt',
    name: 'types-partenaires-fastt',
    component: () => import('@/views/fastt/typesPartenaires/Index.vue'),
    meta: {
      pageTitle: 'Gestion des types partenaires FASTT',
      breadcrumb: [
        {
          text: 'Gestion des types partenaires FASTT',
          active: true,
        },
      ],
      rights: [
        'TYPES_PARTENAIRES_FASTT:read'
      ]
    },
  },
  {
    path: '/types-partenaires-fastt/details/:id',
    name: 'details-types-partenaires-fastt',
    component: () => import('@/views/fastt/typesPartenaires/Details.vue'),
    meta: {
      pageTitle: 'Détails des types partenaires FASTT',
      breadcrumb: [
        {
          text: 'Détails des types partenaires FASTT',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'TYPES_PARTENAIRES_FASTT:read'
      ]
    },
  },


  {
    path: '/validation-partenaires-fastt',
    name: 'validation-partenaires-fastt',
    component: () => import('@/views/fastt/partenaires/Validation/Index.vue'),
    meta: {
      pageTitle: 'Validation des partenaires FASTT',
      breadcrumb: [
        {
          text: 'Validation des partenaires FASTT',
          active: true,
        },
      ],
      rights: [
        'PARTENAIRES_FASTT:admin'
      ]
    },
  },
  {
    path: '/partenaires-fastt',
    name: 'partenaires-fastt',
    component: () => import('@/views/fastt/partenaires/Index.vue'),
    meta: {
      pageTitle: 'Gestion des partenaires FASTT',
      breadcrumb: [
        {
          text: 'Gestion des partenaires FASTT',
          active: true,
        },
      ],
      rights: [
        'PARTENAIRES_FASTT:read'
      ]
    },
  },
  {
    path: '/partenaires-fastt/details/:id',
    name: 'details-partenaires-fastt',
    component: () => import('@/views/fastt/partenaires/Details.vue'),
    meta: {
      pageTitle: 'Détails des partenaires FASTT',
      breadcrumb: [
        {
          text: 'Détails des partenaires FASTT',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'PARTENAIRES_FASTT:read'
      ]
    },
  }
]