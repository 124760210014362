import api from '@/libs/axios'
import { PaginatedList } from '../../models/common/paginatedList'
import { Contact } from '../../models/contacts/contact';
import { Contrat } from '../../models/contrats/contrat';
import { PartenaireFASTT } from '../../models/FASTT/partenaires/partenaire';
import { GuideFonctionnement } from '../../models/guidesFonctionnement/guideFonctionnement';
import { DocumentBeneficiaire } from '../../models/documentsBeneficiaire/documentBeneficiaire';

const resource = 'PartenairesFASTT'

export default class PartenairesFASTTRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string, includePendingValidation?: string, includeOldPartenaires?: string, onlyPendingValidation: boolean = false) : Promise<PaginatedList<PartenaireFASTT>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());
    if (includePendingValidation) params.append('IncludePendingValidation', includePendingValidation.toString());
    if (includeOldPartenaires) params.append('IncludeOldPartenaires', includeOldPartenaires.toString());
    if (onlyPendingValidation) params.append('OnlyPendingValidation', onlyPendingValidation.toString());
    
    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<PartenaireFASTT> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data : PartenaireFASTT) : Promise<PartenaireFASTT> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : PartenaireFASTT) : Promise<PartenaireFASTT> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<PartenaireFASTT> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async getListContactsByPartenaireFASTTId(id: string, pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string): Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contacts`, { params: params } )
    return response.data
  }

  public async createContactOnPartenaireFASTT(id: string, data : Contact) : Promise<Contact> {
    const response = await api.post(`${resource}/${id}/contacts`, data)
    return response.data
  }

  public async deleteContactPartenaireFASTT(id: string, contactId: string) : Promise<Contact> {
    const response = await api.delete(`${resource}/${id}/contacts/${contactId}`)
    return response.data
  }

  public async getContactById(id: string, contactId: string) : Promise<Contact> {
    const response = await api.get(`${resource}/${id}/contacts/${contactId}`)
    return response.data
  }

  public async updateContactPartenaireFASTT(id: string, contactId: string, data: Contact) : Promise<Contact> {
    const response = await api.put(`${resource}/${id}/contacts/${contactId}`, data)
    return response.data
  }

  public async getListContratsByPartenaireFASTTId(
    id: string, 
    pagenumber?: number, 
    pagesize?: number, 
    orderBy?: string, 
    orderByDescending?: 
    boolean, search?: string
  ): Promise<PaginatedList<Contrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contrats`, { params: params } )
    return response.data
  }

  public async createContratOnPartenaireFASTT(id: string, data : Contrat) : Promise<Contrat> {
    console.log('contrat to add : ', data)
    let formData = new FormData();
    formData.append("Fichier", data.fichier);
    if (data.typeContrat) formData.append("TypeContrat", data.typeContrat);
    if (data.nom) formData.append("Nom", data.nom);
    formData.append(
      "Avenant",
      data.avenant ? data.avenant.toString() : false.toString()
    );
    if (data.dateDemarrage)
      formData.append("DateDemarrage", data.dateDemarrage);
    if (data.dateExpiration)
      formData.append("DateExpiration", data.dateExpiration);

    const response = await api.post(`${resource}/${id}/contrats`, formData)
    return response.data
  }

  public async deleteContratPartenaireFASTT(id: string, contratId: string) : Promise<Contrat> {
    const response = await api.delete(`${resource}/${id}/contrats/${contratId}`)
    return response.data
  }

  public async getContratById(id: string, contratId: string) : Promise<Contrat> {
    const response = await api.get(`${resource}/${id}/contrats/${contratId}`)
    return response.data
  }

  public async updateContratPartenaireFASTT(id: string, contratId: string, data: Contrat) : Promise<Contrat> {

    let formData = new FormData();
    formData.append("Fichier", data.fichier);
    if (data.typeContrat) formData.append("TypeContrat", data.typeContrat);
    if (data.nom) formData.append("Nom", data.nom);
    formData.append(
      "Avenant",
      data.avenant ? data.avenant.toString() : false.toString()
    );
    if (data.dateDemarrage)
      formData.append("DateDemarrage", data.dateDemarrage);
    if (data.dateExpiration)
      formData.append("DateExpiration", data.dateExpiration);

    const response = await api.put(`${resource}/${id}/contrats/${contratId}`, formData)
    return response.data
  }

  public async downloadContrat(
    id: string,
    contratId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${id}/contrats/${contratId}/download`
    );
    
    return response.data;
  }
    
  public async createGuideFonctionnementPartenaireFASTT(
    id: string,
    data: GuideFonctionnement
  ): Promise<GuideFonctionnement> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    formData.append("description", data.description);
    const response = await api.post(
      `${resource}/${id}/guidesFonctionnement`,
      formData
    );
    return response.data;
  }

  public async getListGuidesFonctionnementByPartenaireFASTTId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<GuideFonctionnement>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/guidesFonctionnement`, {
      params: params,
    });
    return response.data;
  }

  public async deleteGuideFonctionnement(
    id: string,
    guideFonctionnementId: string
  ): Promise<void> {
    const response = await api.delete(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`
    );
    return response.data;
  }

  public async getGuideFonctionnementById(
    id: string,
    guideFonctionnementId: string
  ): Promise<GuideFonctionnement> {
    const response = await api.get(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`
    );
    return response.data;
  }

  public async updateGuideFonctionnementPartenaireFASTT(
    id: string,
    guideFonctionnementId: string,
    data: GuideFonctionnement
  ): Promise<void> {
    let formData = new FormData();
    formData.append("description", data.description);
    if (data.fichier) formData.append("fichier", data.fichier);
    const response = await api.put(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`,
      formData
    );
    return response.data;
  }

  public async downloadGuideFonctionnement(
    id: string,
    guideFonctionnementId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}/download`
    );

    return response.data;
  }


  public async createDocumentsBeneficiairePartenaireFASTT(
    id: string,
    data: DocumentBeneficiaire
  ): Promise<DocumentBeneficiaire> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    formData.append("description", data.description);
    const response = await api.post(
      `${resource}/${id}/documentsBeneficiaire`,
      formData
    );
    return response.data;
  }

  public async getListDocumentsBeneficiaireByPartenaireFASTTId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<DocumentBeneficiaire>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/documentsBeneficiaire`, {
      params: params,
    });
    return response.data;
  }

  public async deleteDocumentBeneficiaire(
    id: string,
    documentBeneficiaireId: string
  ): Promise<void> {
    const response = await api.delete(
      `${resource}/${id}/documentsBeneficiaire/${documentBeneficiaireId}`
    );
    return response.data;
  }

  public async getDocumentBeneficiaireById(
    id: string,
    documentBeneficiaireId: string
  ): Promise<DocumentBeneficiaire> {
    const response = await api.get(
      `${resource}/${id}/documentsBeneficiaire/${documentBeneficiaireId}`
    );
    return response.data;
  }

  public async updateDocumentBeneficiairePartenaireFASTT(
    id: string,
    documentBeneficiaireId: string,
    data: DocumentBeneficiaire
  ): Promise<void> {
    let formData = new FormData();
    formData.append("description", data.description);
    if (data.fichier) formData.append("fichier", data.fichier);
    const response = await api.put(
      `${resource}/${id}/documentsBeneficiaire/${documentBeneficiaireId}`,
      formData
    );
    return response.data;
  }

  public async downloadDocumentBeneficiaire(
    id: string,
    documentBeneficiaireId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${id}/documentsBeneficiaire/${documentBeneficiaireId}/download`
    );

    return response.data;
  }
}