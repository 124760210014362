import { DaisyEntrepriseCreateModel } from '@/api/models/adherents/adherent'
import { PaginatedList } from '@/api/models/common/paginatedList'
import { EntrepriseDaisy } from '@/api/models/daisy/EntrepriseDaisy'
import api from '@/libs/axios'

const baseUrl = "daisy"
const resource = 'entreprises'

export default class DaisyEntreprisesResource {
  public async post(data : DaisyEntrepriseCreateModel) : Promise<string> {
    const response = await api.post(`${baseUrl}/${resource}`, data)
    return response.data
  }

  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<EntrepriseDaisy>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${baseUrl}/${resource}`, { params: params });
    return response.data;
  }

  public async myRessifMappings(
    entrepriseId: string
  ): Promise<PaginatedList<any>> {

    const response = await api.get(`${baseUrl}/${entrepriseId}/MyRessifMapping`,);
    return response.data;
  }

  public async addMapping(data : any) : Promise<string> {
    const response = await api.post(`${baseUrl}/mapping`, data)
    return response.data
  }

  public async removeMapping(id: string, myRessifEntrepriseId: string) : Promise<any> {
    const response = await api.delete(`${baseUrl}/${id}/mapping/${myRessifEntrepriseId}`)
    return response.data
  }


}
