export default [
  // {
  //   path: '/admin/options/typeContrat',
  //   name: 'admin-options-type-contrat',
  //   component: () => import('@/views/administration/options/typeContrats/TypeContrat.vue'),
  //   meta: {
  //     pageTitle: 'Gérer les types de contrat',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les options',
  //       },
  //       {
  //         text: 'Gérer les types de contrat',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/admin/options/domaines',
  //   name: 'admin-options-domaines',
  //   component: () => import('@/views/administration/options/domaines/Domaine.vue'),
  //   meta: {
  //     pageTitle: 'Gérer les domaines',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les options',
  //       },
  //       {
  //         text: 'Gérer les domaines',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/admin/options/situation',
  //   name: 'admin-options-situations-familiales',
  //   component: () => import('@/views/administration/options/situationFamiliale/SituationFamiliale.vue'),
  //   meta: {
  //     pageTitle: 'Gérer les situations familiales',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les options',
  //       },
  //       {
  //         text: 'Gérer les situations familiales',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/admin/options/categorie-socio-pro',
  //   name: 'admin-options-categories-socio-pro',
  //   component: () => import('@/views/administration/options/categoriesSocioPro/CategorieSocioPro.vue'),
  //   meta: {
  //     pageTitle: 'Gérer les catégories socioprofessionnelles',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les options',
  //       },
  //       {
  //         text: 'Gérer les catégories socioprofessionnelles',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/admin/options/horaires',
  //   name: 'admin-options-horaires',
  //   component: () => import('@/views/administration/options/horaires/Horaires.vue'),
  //   meta: {
  //     pageTitle: 'Gérer les horaires',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les options',
  //       },
  //       {
  //         text: 'Gérer les horaires',
  //         active: true,
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/admin/autorisations',
    name: 'admin-autorisations',
    component: () => import('@/views/administration/authorization/Index.vue'),
    meta: {
      pageTitle: 'Fonctions et droits',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les fonctions',
        },
      ],
      rights: [
        'FONCTIONS:read'
      ]
    },
  },
  {
    path: '/admin/parametres',
    name: 'admin-parametres',
    component: () => import('@/views/administration/parametres/Index.vue'),
    meta: {
      pageTitle: 'Paramètres',
      breadcrumb: [
        {
          text: 'Paramètres',
        },
        {
          text: 'Gérer les paramètres',
        },
      ],
      rights: [
        'PARAMETRES:read'
      ]
    },
  },
  // {
  //   path: '/admin/profils',
  //   name: 'admin-profils',
  //   component: () => import('@/views/administration/utilisateurs/Index.vue'),
  //   meta: {
  //     pageTitle: 'Gestion des utilisateurs',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les utilisateurs',
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/admin/profils/:userDetailsId',
  //   name: 'admin-profils-user-details',
  //   component: () => import('@/views/administration/utilisateurs/Profils/Index.vue'),
  //   meta: {
  //     pageTitle: 'Gestion des utilisateurs',
  //     breadcrumb: [
  //       {
  //         text: 'Administration',
  //       },
  //       {
  //         text: 'Gérer les utilisateurs',
  //       },
  //     ],
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/admin/equipes',
    name: 'admin-equipes',
    component: () => import('@/views/administration/equipes/List.vue'),
    meta: {
      pageTitle: 'Gérer les équipes',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les équipes',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/admin/equipes/:equipeId',
    name: 'admin-equipes-details',
    component: () => import('@/views/administration/equipes/Details.vue'),
    meta: {
      pageTitle: 'Gérer les membres de l\'équipe',
      breadcrumb: [
        {
          text: 'Administration',
        },
        {
          text: 'Gérer les équipes',
          active: true,
        },
        {
          text: 'Gérer les membres de l\'équipe',
          active: true,
        },
      ],
      resource: 'ACL',
      action: 'read',
    },
  }, 
]
