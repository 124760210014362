export default [
  {
    path: '/plannings',
    name: 'plannings',
    component: () => import('@/views/planning/Index.vue'),
    meta: {
      pageTitle: 'Gestion des plannings',
      breadcrumb: [
        {
          text: 'Gestion des plannings',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin',
        'PLANNING_SALARIE:admin'
    ],
      hideLayout: true,
    },
  },
  {
    path: '/types-creneau',
    name: 'types-creneau',
    component: () => import('@/views/planning/typesCreneau/Index.vue'),
    meta: {
      pageTitle: 'Gestion des types de créneau',
      breadcrumb: [
        {
          text: 'Gestion des types de créneau',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/types-creneau/:id',
    name: 'details-type-creneau',
    component: () => import('@/views/planning/typesCreneau/DetailsTypeCreneau.vue'),
    meta: {
      pageTitle: 'Détails du type de créneau',
      breadcrumb: [
        {
          text: 'Détails du type de créneau',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/export-planning-adherent',
    name: 'export-planning-adherent',
    component: () => import('@/views/planning/exportPlanningAdherent/Index.vue'),
    meta: {
      pageTitle: 'Export des plannings des adhérents',
      breadcrumb: [
        {
          text: 'Export des plannings des adhérents',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/modele-export-planning',
    name: 'export-planning-adherent-modele',
    component: () => import('@/views/planning/exportPlanningAdherentModeles/Index.vue'),
    meta: {
      pageTitle: 'Gestion des modèles d\'export de planning des adhérents',
      breadcrumb: [
        {
          text: 'Gestion des modèles d\'export',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/demandes-modifications-plannings',
    name: 'demandes-modifications-plannings',
    component: () => import('@/views/planning/demandesModifications/Index.vue'),
    meta: {
      pageTitle: 'Demandes de modification de planning',
      breadcrumb: [
        {
          text: 'Demandes de modification de planning',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/simulations-plannings',
    name: 'simulations-plannings',
    component: () => import('@/views/planning/simulations/Index.vue'),
    meta: {
      pageTitle: 'Gestion des simulations de planning',
      breadcrumb: [
        {
          text: 'Gestion des simulations de planning',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/structures',
    name: 'structures',
    component: () => import('@/views/structures/Index.vue'),
    meta: {
      pageTitle: 'Gestion des structures',
      breadcrumb: [
        {
          text: 'Gestion des structures',
          active: true,
        },
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  },
  {
    path: '/structures/:id',
    name: 'details-structure',
    component: () => import('@/views/structures/DetailsStructure.vue'),
    meta: {
      pageTitle: 'Détails de la structure',
      breadcrumb: [
        {
          text: 'Détails de la structure',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'PLANNING_ADMIN:admin',
        'PLANNING_SUPERADMIN:admin'
      ]
    },
  }
]

