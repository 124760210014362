import api from "@/libs/axios";
import { EntrepriseWithLastInterventionModel } from "../models/actionsCollectives/actionCollective";
import { PaginatedList } from "../models/common/paginatedList";
import {
  EntrepriseCreateModel,
  Entreprise,
} from "@/api/models/adherents/adherent";
import { Planning } from "../models/planning/planning";
import { Contact } from "../models/contacts/contact";
import { Contrat, UpdateContratModel } from "../models/contrats/contrat";
import { InfosPrestationPlanning, Prestation, PrestationSite } from "../models/prestations/prestation";
import { GuideFonctionnement } from "../models/guidesFonctionnement/guideFonctionnement";
import { AnalyseTactique } from "../models/analyseTactique/analyseTactique";
import { BilanActivite } from "../models/bilansActivite/bilanActivite";
import { typePlanning } from "../models/enums/typePlanningEnum";
import { PlanPrevention } from '../models/plansPrevention/planPrevention';
import { PlanDeveloppement } from '../models/plansDeveloppement/planDeveloppement';
import { Site } from "../models/sites/site";
import { PrestationAdditionnelle, UpdatePrestationAdditionnelleModel } from "../models/prestationsAdditionnelles/prestationAdditionnelle";

const resource = "Entreprises";

export default class EntreprisesResource {
  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    search?: string,
    pertinentOnly?: boolean,
    sortStatut?: string,
    intervenantId?: string
  ): Promise<PaginatedList<Entreprise>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());
    if (pertinentOnly)
      params.append("PertinentOnly", pertinentOnly.toString());
    if (sortStatut) params.append("SortStatut", sortStatut.toString());
    if (intervenantId) params.append("IntervenantId", intervenantId.toString());

    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }

  public async getById(id: string): Promise<Entreprise> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async post(data: EntrepriseCreateModel): Promise<Entreprise> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async update(id: string, data: Entreprise): Promise<Entreprise> {
    console.log('data', data)
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async updateDroitsDiffusion(id: string, data: any): Promise<Entreprise> {
    const response = await api.put(`${resource}/${id}/DroitsDiffusion`, data);
    return response.data;
  }

  public async delete(id: string): Promise<Entreprise> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async getAssistant(id: string): Promise<Planning> {
    const params = new URLSearchParams();
    const response = await api.get(`${resource}/${id}/Assistants`, {
      params: params,
    });
    return response.data;
  }

  public async getConsultant(id: string): Promise<Planning> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}/Consultants`, {
      params: params,
    });
    return response.data;
  }

  public async getInfirmier(id: string): Promise<Planning> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}/Infirmiers`, {
      params: params,
    });
    return response.data;
  }

  public async getPsychologue(id: string): Promise<Planning> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}/Psychologues`, {
      params: params,
    });
    return response.data;
  }

  public async deletePlanning(
    id: string,
    planningId: string
  ): Promise<Planning> {
    const response = await api.delete(
      `${resource}/${id}/Planning/${planningId}`
    );
    return response.data;
  }

  public async getPlanningById(
    id: string,
    planningId: string
  ): Promise<Planning> {
    const response = await api.get(`${resource}/${id}/Planning/${planningId}`);
    return response.data;
  }

  public async updatePlanning(
    id: string,
    planningId: string,
    planningType: typePlanning,
    data: Planning
  ): Promise<Planning> {
    const response = await api.put(
      `${resource}/${id}/Planning/${planningId}?typePlanning=${planningType}`,
      data
    );
    return response.data;
  }

  public async addPlanning(
    id: string,
    utilisateurId: string,
    typePlanning: typePlanning,
    data: Planning
  ): Promise<Planning> {
    const response = await api.post(
      `${resource}/${id}/Planning?utilisateurId=${utilisateurId}&typePlanning=${typePlanning}`,
      data
    );
    return response.data;
  }

  public async createContactOnEntreprise(
    id: string,
    data: Contact
  ): Promise<Contact> {
    const response = await api.post(`${resource}/${id}/contacts`, data);
    return response.data;
  }

  public async createContratOnEntreprise(
    id: string,
    data: Contrat
  ): Promise<Contrat> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    if (data.typeContrat) formData.append("typeContrat", data.typeContrat);
    if (data.nom) formData.append("nom", data.nom);
    formData.append(
      "avenant",
      data.avenant ? data.avenant.toString() : false.toString()
    );
    if (data.dateDemarrage)
      formData.append("dateDemarrage", data.dateDemarrage);
    if (data.dateExpiration)
      formData.append("dateExpiration", data.dateExpiration);

    const response = await api.post(`${resource}/${id}/contrats`, formData);
    return response.data;
  }

  public async createPrestationAdditionnelleOnEntreprise(
    id: string,
    data: PrestationAdditionnelle
  ): Promise<PrestationAdditionnelle> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    if (data.dateVente) formData.append("dateVente", data.dateVente);
    if (data.prix) formData.append("prix", data.prix.toString());
    formData.append("type", data.type)
    const response = await api.post(`${resource}/${id}/prestationsAdditionnelles`, formData);
    return response.data;
  }

  public async createGuideFonctionnementEntreprise(
    id: string,
    data: GuideFonctionnement
  ): Promise<GuideFonctionnement> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    formData.append("description", data.description);
    const response = await api.post(
      `${resource}/${id}/guidesFonctionnement`,
      formData
    );
    return response.data;
  }

  public async createBilanActiviteEntreprise(
    id: string,
    data: BilanActivite
  ): Promise<BilanActivite> {
    let formData = new FormData();
    formData.append("fichier", data.fichier);
    formData.append("description", data.description);
    formData.append("dateEnvoi", data.dateEnvoi);
    formData.append("datePresentation", data.datePresentation);
    const response = await api.post(
      `${resource}/${id}/bilansActivite`,
      formData
    );
    return response.data;
  }

  public async getListContratsByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    orderBy?: string,
    orderByDescending?: boolean
  ): Promise<PaginatedList<Contrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contrats`, {
      params: params,
    });
    return response.data;
  }

  public async getListPrestationsAdditionnellesByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<PrestationAdditionnelle>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/prestationsAdditionnelles`, {
      params: params,
    });
    return response.data;
  }

  public async countTotauxPrestationsAdditionnellesByEntrepriseId(
    id: string
  ): Promise<PaginatedList<PrestationAdditionnelle>> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}/CountPrestationsAdditionnelles`);
    return response.data;
  }

  public async getListGuidesFonctionnementByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    search?: string
  ): Promise<PaginatedList<GuideFonctionnement>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/guidesFonctionnement`, {
      params: params,
    });
    return response.data;
  }

  public async getListBilansActiviteByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<GuideFonctionnement>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/bilansActivite`, {
      params: params,
    });
    return response.data;
  }

  public async getListContactsByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    search?: string
  ): Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contacts`, {
      params: params,
    });
    return response.data;
  }

  public async getContratById(id: string, contactId: string): Promise<Contrat> {
    const response = await api.get(`${resource}/${id}/contrats/${contactId}`);
    return response.data;
  }

  public async getPrestationAdditionnelleById(id: string, prestationAdditionnelleId: string): Promise<PrestationAdditionnelle> {
    const response = await api.get(`${resource}/${id}/prestationsAdditionnelles/${prestationAdditionnelleId}`);
    return response.data;
  }

  public async getContactById(id: string, contactId: string): Promise<Contact> {
    const response = await api.get(`${resource}/${id}/contacts/${contactId}`);
    return response.data;
  }

  public async deleteGuideFonctionnement(
    id: string,
    guideFonctionnementId: string
  ): Promise<void> {
    const response = await api.delete(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`
    );
    return response.data;
  }

  public async deleteBilanActivite(
    id: string,
    bilanActiviteId: string
  ): Promise<void> {
    const response = await api.delete(
      `${resource}/${id}/bilansActivite/${bilanActiviteId}`
    );
    return response.data;
  }

  public async deleteContratEntreprise(
    id: string,
    contratId: string
  ): Promise<Contrat> {
    const response = await api.delete(
      `${resource}/${id}/contrats/${contratId}`
    );
    return response.data;
  }

  public async deletePrestationAdditionnelleEntreprise(
    id: string,
    prestationAdditionnelleId: string
  ): Promise<Contrat> {
    const response = await api.delete(
      `${resource}/${id}/prestationsAdditionnelles/${prestationAdditionnelleId}`
    );
    return response.data;
  }

  public async deleteContactEntreprise(
    id: string,
    contactId: string
  ): Promise<Contact> {
    const response = await api.delete(
      `${resource}/${id}/contacts/${contactId}`
    );
    return response.data;
  }

  public async getGuideFonctionnementById(
    id: string,
    guideFonctionnementId: string
  ): Promise<GuideFonctionnement> {
    const response = await api.get(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`
    );
    return response.data;
  }

  public async getBilanActiviteById(
    id: string,
    bilanActiviteId: string
  ): Promise<BilanActivite> {
    const response = await api.get(
      `${resource}/${id}/bilansActivite/${bilanActiviteId}`
    );
    return response.data;
  }

  public async updateGuideFonctionnementEntreprise(
    id: string,
    guideFonctionnementId: string,
    data: GuideFonctionnement
  ): Promise<void> {
    let formData = new FormData();
    formData.append("description", data.description);
    if (data.fichier) formData.append("fichier", data.fichier);
    const response = await api.put(
      `${resource}/${id}/guidesFonctionnement/${guideFonctionnementId}`,
      formData
    );
    return response.data;
  }

  public async updateBilanActiviteEntreprise(
    id: string,
    bilanActiviteId: string,
    data: BilanActivite
  ): Promise<void> {
    let formData = new FormData();
    formData.append("description", data.description);
    formData.append("datePresentation", data.datePresentation);
    formData.append("dateEnvoi", data.dateEnvoi);
    if (data.fichier) formData.append("fichier", data.fichier);
    const response = await api.put(
      `${resource}/${id}/bilansActivite/${bilanActiviteId}`,
      formData
    );
    return response.data;
  }

  public async updateContratEntreprise(
    id: string,
    contratId: string,
    data: UpdateContratModel
  ): Promise<Contrat> {

    let formData = new FormData();
    formData.append("fichier", data.fichier);
    if (data.typeContrat) formData.append("typeContrat", data.typeContrat);
    if (data.nom) formData.append("nom", data.nom);
    formData.append(
      "avenant",
      data.avenant ? data.avenant.toString() : false.toString()
    );
    if (data.dateDemarrage)
      formData.append("dateDemarrage", data.dateDemarrage);
    if (data.dateExpiration)
      formData.append("dateExpiration", data.dateExpiration);


    const response = await api.put(
      `${resource}/${id}/contrats/${contratId}`,
      formData
    );
    return response.data;
  }

  public async updatePrestationAdditionnelleEntreprise(
    id: string,
    prestationAdditionnelleId: string,
    data: UpdatePrestationAdditionnelleModel
  ): Promise<PrestationAdditionnelle> {
    

    let formData = new FormData();
    formData.append("fichier", data.fichier);

    if (data.dateVente) 
        formData.append("dateVente", data.dateVente);
      
    if (data.prix) 
      formData.append("prix", data.prix.toString());
    if (data.type) 
      formData.append("type", data.type);


    const response = await api.put(
      `${resource}/${id}/prestationsAdditionnelles/${prestationAdditionnelleId}`,
      formData
    );
    return response.data;
  }

  public async updateContactEntreprise(
    id: string,
    contactId: string,
    data: Contact
  ): Promise<Contact> {
    const response = await api.put(
      `${resource}/${id}/contacts/${contactId}`,
      data
    );
    return response.data;
  }

  public async downloadBilanActivite(
    entrepriseId: string,
    bilanActiviteId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${entrepriseId}/bilansActivite/${bilanActiviteId}/download`
    );
    return response.data;
  }

  public async downloadGuideFonctionnement(
    entrepriseId: string,
    guideFonctionnementId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${entrepriseId}/guidesFonctionnement/${guideFonctionnementId}/download`
    );
    return response.data;
  }

  public async getListAnalysesTactiqueByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    search?: string
  ): Promise<PaginatedList<AnalyseTactique>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/analysesTactique`, {
      params: params,
    });
    return response.data;
  }

  public async postAnalyseTactique(
    id: string,
    data: AnalyseTactique
  ): Promise<AnalyseTactique> {
    const response = await api.post(`${resource}/${id}/analysesTactique`, data);
    return response.data;
  }

  public async downloadContrat(
    entrepriseId: string,
    contratId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${entrepriseId}/contrats/${contratId}/download`
    );

    return response.data;
  }

  public async downloadPrestationAdditionnelle(
    entrepriseId: string,
    prestationAdditionnelleId: string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${entrepriseId}/prestationsAdditionnelles/${prestationAdditionnelleId}/download`
    );

    return response.data;
  }

  public async getListPlansDeveloppementByEntrepriseId(id: string): Promise<PlanDeveloppement> {

    const response = await api.get(`${resource}/${id}/plansDeveloppement`);
    return response.data;
  }

  public async createPlanDeveloppementEntreprise(id: string, data : PlanDeveloppement) : Promise<PlanDeveloppement> {
    // console.log('content', JSON.stringify(data.contenu)
    // .replace(/\n/g, '\\\\n'))
    let formData = new FormData();
    formData.append('Annee', data.annee.toString());
    formData.append('Libelle', data.libelle);
    formData.append('Contenu', data.contenu);
    const response = await api.post(`${resource}/${id}/plansDeveloppement`, formData);
    return response.data;
  }

  public async deletePLanDeveloppement(id: string, planDeveloppementId: string, index: number) : Promise<void> {
    const response = await api.delete(`${resource}/${id}/plansDeveloppement/${planDeveloppementId}?index=${index}`);
    return response.data;
  }

  public async updatePlanDeveloppementEntreprise(id: string, planDeveloppementId: string, index: number, data: PlanDeveloppement) : Promise<void> {
    let formData = new FormData();
    formData.append('Contenu', data.contenu);
    console.log(formData)
    const response = await api.put(`${resource}/${id}/plansDeveloppement/${planDeveloppementId}?index=${index}`, formData);
    return response.data;
  }

  public async getListPlansPreventionByEntrepriseId(id: string, pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<PlanPrevention>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());

    const response = await api.get(`${resource}/${id}/plansPrevention`, { params: params } );
    return response.data;
  }

  public async createPlanPreventionEntreprise(id: string, data : PlanPrevention) : Promise<PlanPrevention> {
    let formData = new FormData();
    formData.append('fichier', data.fichier);
    formData.append('description', data.description);
    formData.append('dateSignature', data.dateSignature);
    formData.append('dateValidite', data.dateValidite);

    const response = await api.post(`${resource}/${id}/plansPrevention`, formData);
    return response.data;
  }

  public async deletePlanPrevention(id: string, planPreventionId: string) : Promise<void> {
    const response = await api.delete(`${resource}/${id}/plansPrevention/${planPreventionId}`);
    return response.data;
  }

  public async downloadPlanPrevention(entrepriseId: string, planPreventionId: string) : Promise<any> {
    const response = await api.get(`${resource}/${entrepriseId}/plansPrevention/${planPreventionId}/download`);
    return response.data;
  }

  public async getPlanPreventionById(id: string, planPreventionId: string) : Promise<PlanPrevention> {
    const response = await api.get(`${resource}/${id}/plansPrevention/${planPreventionId}`);
    return response.data;
  }

  public async updatePlanPreventionEntreprise(id: string, planPreventionId: string, data: PlanPrevention) : Promise<void> {
    let formData = new FormData();
    formData.append('description', data.description);
    formData.append('dateSignature', data.dateSignature);
    formData.append('dateValidite', data.dateValidite);
    if(data.fichier) formData.append('fichier', data.fichier);
    const response = await api.put(`${resource}/${id}/plansPrevention/${planPreventionId}`, formData);
    return response.data;
  }

  public async getListInformationOperationnelleByEntrepriseId(
    id: string
  ): Promise<PaginatedList<any>> {

    const response = await api.get(`${resource}/${id}/informationsOperationnelle`);
    return response.data;
  }

  public async updateInformationOperationnelle(id: string, data: any) : Promise<void> {
    let formData = new FormData();
    formData.append('Valeur', data);
    const response = await api.put(`${resource}/${id}/informationsOperationnelle`, formData);
    return response.data;
  }

  public async getListInformationOperationnelleBudgetByEntrepriseId(
    id: string
  ): Promise<PaginatedList<any>> {

    const response = await api.get(`${resource}/${id}/InformationsOperationnelleBudget`);
    return response.data;
  }

  public async updateInformationOperationnelleBudget(id: string, data: any) : Promise<void> {
    let formData = new FormData();
    formData.append('Valeur', data);
    const response = await api.put(`${resource}/${id}/InformationsOperationnelleBudget`, formData);
    return response.data;
  }

  public async getListSitesByEntrepriseId(
    id: string,
  ): Promise<Site[]> {
    const response = await api.get(`${resource}/${id}/sites`);
    return response.data;
  }

  public async getListPrestationsSitesByPrestationId(
    id: string,
    prestationId: string,
    annee: number
  ): Promise<PrestationSite[]> {
    const params = new URLSearchParams();
    if (annee) params.append('Annee', annee.toString());
    params.append('PeriodiciteId', id);

    const response = await api.get(`${resource}/${id}/prestations/${prestationId}/prestationsSites`, {params: params});
    return response.data;
  }

  public async createSiteEntreprise(
    entrepriseId: string,
    data: any
  ): Promise<Site> {
    const response = await api.post(`${resource}/${entrepriseId}/sites`, data);
    return response.data;
  }

  public async deleteSiteEntreprise(entrepriseId: string, siteId: string) : Promise<void> {
    const response = await api.delete(`${resource}/${entrepriseId}/sites/${siteId}`);
    return response.data;
  }

  public async updateSiteEntreprise(
    id: string,
    siteId: string,
    data: Contact
  ): Promise<Site> {
    const response = await api.put(
      `${resource}/${id}/sites/${siteId}`,
      data
    );
    return response.data;
  }

  public async getSiteById(
    id: string,
    siteId: string
  ): Promise<Site> {
    const response = await api.get(
      `${resource}/${id}/sites/${siteId}`
    );
    return response.data;
  }

  public async getListContactBySite(id: string, siteId: string, pagenumber?: number, pagesize?: number, search?: string, orderBy?: string, orderByDescending?: boolean,): Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/Sites/${siteId}/contacts`, { params: params } );
    return response.data;
  }

  public async addContactOnSiteEntreprise(
    entrepriseId: string,
    siteId: string,
    contactId: string,
    data: any
  ): Promise<Contact> {
    const response = await api.post(`${resource}/${entrepriseId}/sites/${siteId}/contacts/${contactId}`, data);
    return response.data;
  }

  public async deleteContactOnSiteEntreprise(entrepriseId: string, siteId: string, contactId: string) : Promise<void> {
    const response = await api.delete(`${resource}/${entrepriseId}/sites/${siteId}/contacts/${contactId}`);
    return response.data;
  }

  public async getListPrestationsByEntrepriseId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<Prestation>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/prestations`, {
      params: params,
    });
    return response.data;
  }

  public async getPrestationById(id: string, prestationId: string): Promise<Prestation> {
    const response = await api.get(`${resource}/${id}/prestations/${prestationId}`);
    return response.data;
  }

  public async createPrestationOnEntreprise(
    id: string,
    data: Prestation
  ): Promise<Prestation> {
    const response = await api.post(`${resource}/${id}/prestations`, data);
    return response.data;
  }

  public async updatePrestationEntreprise(
    id: string,
    prestationId: string,
    data: Prestation
  ): Promise<Prestation> {
    const response = await api.put(
      `${resource}/${id}/prestations/${prestationId}`,
      data
    );
    return response.data;
  }

  public async deletePrestationEntreprise(
    id: string,
    prestationId: string
  ): Promise<Prestation> {
    const response = await api.delete(
      `${resource}/${id}/prestations/${prestationId}`
    );
    return response.data;
  }

  public async getInfosPrestationById(
    id: string,
    prestationId: string,
    startDate: string
  ): Promise<InfosPrestationPlanning> {
    const params = new URLSearchParams();
    if (startDate) params.append('StartDate', startDate.toString());

    const response = await api.get(`${resource}/${id}/Prestations/${prestationId}/InfosPrestations`, { params: params } )
    return response.data
  }
}
