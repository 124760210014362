import api from '@/libs/axios'
import { NatureInterventionCollective } from '../models/actionsCollectives/natureIntervention';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'NaturesInterventionCollective'

export default class NaturesInterventionCollectiveRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifOnly?: boolean): Promise<PaginatedList<NatureInterventionCollective>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifOnly) params.append('ActifOnly', actifOnly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
