import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Contact } from '../models/contacts/contact';
import { Contrat, UpdateContratModel } from '../models/contrats/contrat';
import { Partenaire } from '../models/partenaires/partenaire';

const resource = 'Partenaires'

export default class PartenairesRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string, pertinentOnly?: boolean) : Promise<PaginatedList<Partenaire>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());
    if (pertinentOnly) params.append('PertinentOnly', pertinentOnly.toString());

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Partenaire> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data : Partenaire) : Promise<Partenaire> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Partenaire) : Promise<Partenaire> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Partenaire> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async getListContactsByPartenaireId(id: string, pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string): Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/contacts`, { params: params } )
    return response.data
  }

  public async createContactOnPartenaire(id: string, data : Contact) : Promise<Contact> {
    const response = await api.post(`${resource}/${id}/contacts`, data)
    return response.data
  }

  public async deleteContactPartenaire(id: string, contactId: string) : Promise<Contact> {
    const response = await api.delete(`${resource}/${id}/contacts/${contactId}`)
    return response.data
  }

  public async getContactById(id: string, contactId: string) : Promise<Contact> {
    const response = await api.get(`${resource}/${id}/contacts/${contactId}`)
    return response.data
  }

  public async updateContactPartenaire(id: string, contactId: string, data: Contact) : Promise<Contact> {
    const response = await api.put(`${resource}/${id}/contacts/${contactId}`, data)
    return response.data
  }

  public async getListContratsByPartenaireId(
    id: string, 
    pagenumber?: number, 
    pagesize?: number, 
    search?: string,
    orderBy?: string,
    orderByDescending?: boolean
  ): Promise<PaginatedList<Contrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());
    
    const response = await api.get(`${resource}/${id}/contrats`, { params: params } )
    return response.data
  }

  public async createContratOnPartenaire(id: string, data : Contrat) : Promise<Contrat> {
    let formData = new FormData();
    formData.append('fichier', data.fichier)
    if (data.typeContrat) formData.append('typeContrat', data.typeContrat)
    if (data.nom) formData.append("nom", data.nom);
    formData.append('avenant', data.avenant ? data.avenant.toString() : false.toString())
    if (data.dateDemarrage) formData.append('dateDemarrage', data.dateDemarrage)
    if (data.dateExpiration) formData.append('dateExpiration', data.dateExpiration)
 
    const response = await api.post(`${resource}/${id}/contrats`, formData)
    return response.data
  }

  public async deleteContratPartenaire(id: string, contratId: string) : Promise<Contrat> {
    const response = await api.delete(`${resource}/${id}/contrats/${contratId}`)
    return response.data
  }

  public async getContratById(id: string, contactId: string) : Promise<Contrat> {
    const response = await api.get(`${resource}/${id}/contrats/${contactId}`)
    return response.data
  }

  public async updateContratPartenaire(id: string, contratId: string, data: UpdateContratModel) : Promise<Contrat> {
    const response = await api.put(`${resource}/${id}/contrats/${contratId}`, data)
    return response.data
  }

  public async downloadContrat(partenaireId: string, contratId: string) : Promise<any> {
    const response = await api.get(`${resource}/${partenaireId}/contrats/${contratId}/download`);
    return response.data;
  }
}