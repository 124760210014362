import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import {
  CreateProcessusModel,
  Processus,
  UpdateProcessusModel,
} from "../models/fichesIncident/processus";

const resource = "Processus";

export default class ProcessusResource {
  public async searchPaginatedList(
    pageNumber?: number,
    pageSize?: number,
    search?: string,
    orderBy?: string,
    orderBydescending?: boolean,
  ): Promise<PaginatedList<Processus>> {
    const params = new URLSearchParams();
    if (pageNumber) params.append("pageNumber", pageNumber.toString());
    if (pageSize) params.append("pageSize", pageSize.toString());
    if (search) params.append("search", search);
    if (orderBy) params.append("orderBy", orderBy);
    if (orderBydescending)
      params.append("orderBydescending", orderBydescending.toString());
    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }

  public async getById(id: string): Promise<Processus> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async post(data: CreateProcessusModel): Promise<Processus> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async update(id: string, data: UpdateProcessusModel): Promise<string> {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async delete(id: string): Promise<void> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }
}
