import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { 
  ExportPlanningAdherentModele, 
  CreateExportPlanningAdherentModeleModel,
  UpdateExportPlanningAdherentModeleModel
 } from '../models/creneaux/exportPlanningAdherentModele';


const resource = 'exportPlanningAdherentModeles'

export default class ExportPlanningAdherentModelesResource {
  public async paginatedList(search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<ExportPlanningAdherentModele>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<ExportPlanningAdherentModele> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: CreateExportPlanningAdherentModeleModel) : Promise<CreateExportPlanningAdherentModeleModel> {
    let formData = new FormData();

    if (data.imagePrincipaleFichier) formData.append("imagePrincipaleFichier", data.imagePrincipaleFichier);
    if (data.imageSecondaireFichier) formData.append("imageSecondaireFichier", data.imageSecondaireFichier);
    if (data.couleurPrincipale) formData.append("couleurPrincipale", data.couleurPrincipale);
    if (data.couleurSecondaire) formData.append("couleurSecondaire", data.couleurSecondaire);
    if (data.libelle) formData.append("libelle", data.libelle);

    const response = await api.post(`${resource}`, formData)
    return response.data
  }

  public async update(id: string, data : UpdateExportPlanningAdherentModeleModel) : Promise<ExportPlanningAdherentModele> {
    let formData = new FormData();

    if (data.imagePrincipaleFichier) formData.append("imagePrincipaleFichier", data.imagePrincipaleFichier);
    if (data.imageSecondaireFichier) formData.append("imageSecondaireFichier", data.imageSecondaireFichier);
    if (data.couleurPrincipale) formData.append("couleurPrincipale", data.couleurPrincipale);
    if (data.couleurSecondaire) formData.append("couleurSecondaire", data.couleurSecondaire);
    if (data.libelle) formData.append("libelle", data.libelle);
    if (data.removeImageSecondaire != undefined) formData.append("removeImageSecondaire", data.removeImageSecondaire.toString());

    const response = await api.put(`${resource}/${id}`, formData)
    return response.data
  }

  public async delete(id : string) : Promise<ExportPlanningAdherentModele> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
}
