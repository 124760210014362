export default [
    {
      path: '/equipes',
      name: 'equipes',
      component: () => import('@/views/equipes/Index.vue'),
      meta: {
        pageTitle: 'Gestion des equipes',
        breadcrumb: [
          {
            text: 'Gestion des equipes',
            active: true,
          },
        ],
        rights: [
          'EQUIPES:read'
        ]
      },
    },
    {
      path: '/equipes/details/:id',
      name: 'details-equipe',
      component: () => import('@/views/equipes/DetailsEquipe.vue'),
      meta: {
        pageTitle: 'Détails de l\'équipe',
        breadcrumb: [
          {
            text: 'Détails de l\'équipe',
            active: true,
          },
          {
            text: 'Détails'
          }
        ],
        rights: [
          'EQUIPES:read'
        ]
      },
    }    
  ]