import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Changelog } from '../models/changelogs/changelog';
import { ChangelogApplication } from '../models/enums/changelogApplication';


const resource = 'changelogs'

export default class ChangelogsResource {
  public async paginatedList(search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Changelog>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Changelog> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: Changelog) : Promise<Changelog> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Changelog) : Promise<Changelog> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Changelog> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async paginatedListApplication(application: string, tenantId: string, search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<ChangelogApplication>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (application) params.append('Application', application.toString()); 
    if (tenantId) params.append('TenantId', tenantId.toString()); 

    const response = await api.get("changelogsApplication", { params: params } )
    return response.data
  }
}
