import Swal from 'sweetalert2/dist/sweetalert2.js';

const successAlert = Swal.mixin({
    icon: 'success',
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false,
    showConfirmButton: false,
    timer: 2000,
}) 

const errorAlert = Swal.mixin({
  icon: 'error',
  title: 'Oops...',
  customClass: {
    confirmButton: 'btn btn-primary',
  },
  buttonsStyling: false
}) 

export { successAlert, errorAlert }