

















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "edit-icon"
})
export default class EditIcon extends Vue {
  
}
