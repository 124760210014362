import api from '@/libs/axios'
import { PartiePrenante } from '../models/actionsCollectives/partiePrenante';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'PartiesPrenantes'

export default class PartiesPrenantesRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, includeType?: boolean): Promise<PaginatedList<PartiePrenante>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (includeType) params.append('IncludeType', includeType.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
