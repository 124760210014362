export default [
    {
      path: '/admin/gestionAvis',
      name: 'gestion-avis',
      component: () => import('@/views/gestionAvis/Index.vue'),
      meta: {
        pageTitle: 'Gérer les avis',
        breadcrumb: [
          {
            text: 'Gérer les avis',
            active: true,
          },
        ],
        rights: [
            'INACCESSIBLE'
          ]
      },
    }  
]
