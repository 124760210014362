import api from '@/libs/axios'
import { PaginatedList } from '../../models/common/paginatedList'
import { Contact } from '../../models/contacts/contact';
import { Contrat, UpdateContratModel } from '../../models/contrats/contrat';
import { Partenaire } from '../../models/partenaires/partenaire';
import { TypePartenaireFASTT, TypePartenaireFasttCreateModel } from '@/api/models/FASTT/typesPartenaire/typesPartenaire';

const resource = 'TypesPartenaireFASTT'
const resourceMembres = 'TypesPartenaireFASTTMembres'

export default class TypesPartenaireFASTTRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string) : Promise<PaginatedList<TypePartenaireFASTT>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async post(data : TypePartenaireFasttCreateModel) : Promise<TypePartenaireFASTT> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async getById(id: string) : Promise<TypePartenaireFASTT> {
    const response = await api.get(`${resource}/${id}`)
    console.log('data', response.data)
    return response.data
  }

  public async update(id: string, data : Partenaire) : Promise<Partenaire> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Partenaire> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
  
  public async addMembre(id : string, partenaireId: string) : Promise<Partenaire> {
    const response = await api.post(`${resource}/${id}/membres/${partenaireId}`)
    return response.data
  }


  public async removeMembre(id : string, partenaireId: string) : Promise<Partenaire> {
    const response = await api.delete(`${resource}/${id}/membres/${partenaireId}`)
    return response.data
  }

}