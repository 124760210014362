import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { CreatePreferenceUtilisateurModel, PreferenceUtilisateur } from '../models/preferencesUtilisateur/preferenceUtilisateur';

const resource = 'preferencesUtilisateur'

export default class PreferencesUtilisateurResource {

  public async paginatedListPreferencesUtilisateur(pagenumber?: number, pagesize?: number, search?: string): Promise<PaginatedList<PreferenceUtilisateur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (search) params.append('Search', search.toString());

    const response = await api.get(`${resource}`, { params: params })
    return response.data
  }
  
  public async get() : Promise<PreferenceUtilisateur> {
    const response = await api.get(`${resource}`)
    return response.data
  }

  public async getById(preferenceUtilisateurId : string) : Promise<PreferenceUtilisateur> {
    const response = await api.get(`${resource}/${preferenceUtilisateurId}`)
    return response.data
  }

  public async delete(preferenceUtilisateurId: string) : Promise<PreferenceUtilisateur> {
    const response = await api.delete(`${resource}/${preferenceUtilisateurId}`)
    return response.data
  }

  public async post( data : CreatePreferenceUtilisateurModel) : Promise<PreferenceUtilisateur> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async put( id : string, data : PreferenceUtilisateur) : Promise<PreferenceUtilisateur> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }
}
