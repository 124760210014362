import api from "@/libs/axios";
import {
  CreateInformationAdditionnelle,
  InformationAdditionnelle,
  InformationAdditionnellePreview,
} from "../models/informationAdditionnelle/informationAdditionnelle";
import { PaginatedList } from "../models/common/paginatedList";
import { TypeEntiteEnum } from "../models/enums/typeEntiteEnum";

const resource = "InformationsAdditionnelles";

export default class InformationsAdditionnellesRessources {
  public async paginatedList(
    TypeEntite?: TypeEntiteEnum,
    TypeEntiteId?: string,
    pagenumber?: number,
    pagesize?: number
  ): Promise<PaginatedList<InformationAdditionnelle>> {
    const params = new URLSearchParams();

    if (TypeEntite) params.append("TypeEntite", TypeEntite);
    if (TypeEntiteId) params.append("TypeEntiteId", TypeEntiteId);
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());

    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }

  public async getById(id: string): Promise<any> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async post(data: CreateInformationAdditionnelle): Promise<InformationAdditionnelle> {
    let formData = new FormData();

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));

    if (data.message) {
      formData.append("message", data.message);
    }
    if (data.icone) {
      formData.append("icone", data.icone);
    }
    if (data.couleur) {
      formData.append("couleur", data.couleur);
    }
    if (data.titre) {
      formData.append("titre", data.titre);
    }
    if (data.fonctionsAllowed) {
      data.fonctionsAllowed.forEach((str) => {
        formData.append('fonctionsAllowed', str)
      })
    }
    if (data.groupesAllowed) {
      data.groupesAllowed.forEach((str) => {
        formData.append('groupesAllowed', str)
      })
    }
    if (data.cibleId) {
      formData.append("cibleId", data.cibleId);
    }
    if (data.cibleType) {
      formData.append("cibleType", data.cibleType);
    }
    if(data.refreshDateDerniereVisite) {
      formData.append("refreshDateDerniereVisite", data.refreshDateDerniereVisite.toString())
    }

    const response = await api.post(`${resource}`, formData);
    return response.data;
  }

  public async update(
    id: string,
    data: InformationAdditionnelle
  ): Promise<InformationAdditionnelle> {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async previewRights(
    data: InformationAdditionnellePreview
  ): Promise<InformationAdditionnelle> {
    const response = await api.post(`${resource}/previewRights`, data);
    return response.data;
  }

  public async downloadInformationAdditionnelleFile(
    id: string, fichierId : string
  ): Promise<any> {
    const response = await api.get(
      `${resource}/${id}/download/${fichierId}`
    );
    return response.data;
  }

  public async delete(id: string): Promise<InformationAdditionnelle> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }
}
