import useUserDroits from '@/auth/useUserDroits'

export const can = {
  canActivate: (rightCode: string) => {
    const { listDroits } = useUserDroits()
    return listDroits.value.indexOf(rightCode) !== -1
  }
}

export const is = {
  hasFonction: (fonctionCode: string) => {
    const { listFonctions } = useUserDroits()
    return listFonctions.value.indexOf(fonctionCode) !== -1
  }
}