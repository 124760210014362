import api from '@/libs/axios'
import { Parametre, ParametreCreateModel } from '../models/parametres/parametre';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'parametres'

export default class ParametresRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<Parametre>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<any> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async getByNom(nom: string) : Promise<any> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/Nom/${nom}`, { params: params })
    return response.data
  }

  public async post(data : ParametreCreateModel) : Promise<Parametre> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Parametre) : Promise<Parametre> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Parametre> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
}
