import appApiTypes from '@/api'
import user from '../user'


function IsJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    demandes: localStorage.getItem('demandes') && IsJsonString(localStorage.getItem('demandes')) ? JSON.parse(localStorage.getItem('demandes')) : null,

  },
  getters: {
    demandes: state => state.demandes,
  },

  mutations: {
    SET_DEMANDES(state, demandes) {
      localStorage.setItem('demandes', JSON.stringify(demandes))
      state.demandes = demandes
    }
  },
  actions: {
    setDemandes({ commit }, demandes) {
      commit('SET_DEMANDES', demandes)
    },
    fetchDemandes({ commit }, payload) {
      return new Promise((resolve, reject) => {
        appApiTypes.myressif.utilisateurs
          .listDemandesModifications(user.state.user.uid)
          .then(response => { 
            commit('SET_DEMANDES', response)
          })
          .catch(error => {
            console.log(error);
            reject(error)
          });

      })
    }
  }
}