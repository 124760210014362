import { FonctionRessifnetFastt } from '@/api/models/ressifnetFastt/FonctionRessifnetFastt'
import api from '@/libs/axios'

const baseUrl = "ressifnetFastt"
const resource = 'fonctions'

export default class RessifnetFasttFonctionsResource {

  public async listFonction(
  ): Promise<FonctionRessifnetFastt[]> {

    const response = await api.get(`${baseUrl}/${resource}`);
    return response.data;
  }
}
