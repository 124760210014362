import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import { Identity } from "../models/authorization/identities/identity";
import { Fonction } from "../models/authorization/fonctions/fonction";

const resource = "Identities";

export default class IdentitiesResource {
  public async get(
    claimValue: string,
    includeDroits: boolean = false
  ): Promise<PaginatedList<Identity>> {
    const response = await api.get(
      `${resource}/${claimValue}?includeDroits=${includeDroits}`
    );
    return response.data;
  }

  public async addFonction(
    identityId: string,
    fonctionId: string
  ): Promise<any> {
    const response = await api.post(`${resource}/${identityId}/fonctions`, {
      fonctionId: fonctionId,
    });
    return response.data;
  }

  public async listFonctions(identityId: string): Promise<Fonction[]> {
    const response = await api.get(`${resource}/${identityId}/fonctions`);
    return response.data;
  }

  public async deleteFonction(
    identityId: string,
    fonctionId: string
  ): Promise<Fonction> {
    const response = await api.delete(
      `${resource}/${identityId}/Fonctions/${fonctionId}`
    );
    return response.data;
  }

  // public async updateDroit(identityId: string, droitId: string, flag: any) : Promise<any> {
  //   const response = await api.put(`${resource}/${identityId}/droits/${droitId}`, { flag: flag })
  //   return response.data
  // }

  public async addDroit(
    identityId: string,
    droitId: string,
    flag: any
  ): Promise<any> {
    const response = await api.post(`${resource}/${identityId}/droits`, {
      identityId: identityId,
      droitId: droitId,
      flag: flag,
    });
    return response.data;
  }

  public async deleteDroit(
    identityId: string,
    droitId: string,
    flag: any
  ): Promise<Fonction> {
    const response = await api.delete(
      `${resource}/${identityId}/droits/${droitId}/${flag}`
    );
    return response.data;
  }
}
