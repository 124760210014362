export default [
  {
    path: "/fiches-incident",
    name: "fiches-incident",
    component: () => import("@/views/fichesIncident/Index.vue"),
    meta: {
      pageTitle: "Fiches incident",
      breadcrumb: [
        {
          text: "Liste des fiches incident",
          active: true,
        },
      ],
      rights: [
        'FICHES_INCIDENT:read',
      ]
    },
  },
  {
    path: "/fiches-incident/details/:id",
    name: "fiches-incident-details",
    component: () => import("@/views/fichesIncident/Details.vue"),
    meta: {
      pageTitle: "Fiches incident",
      breadcrumb: [
        {
          text: "Détails de la fiche incident",
          active: true,
        },
      ],
      rights: [
        'FICHES_INCIDENT:read',
      ]
    },
  },
  {
    path: "/fiches-incident-create",
    name: "fiches-incident-create",
    component: () => import("@/views/fichesIncident/Create.vue"),
    meta: {
      pageTitle: "Fiches incident",
      breadcrumb: [
        {
          text: "Créer une fiche incident",
          active: true,
        },
      ],
      rights: [
        'FICHES_INCIDENT:create',
      ]
    },
  },
];
