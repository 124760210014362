import TypeContratsResource from "./ressources/typeContrats"
import DomainesResource from "./ressources/domaines"
import SituationsFamilialeResource from "./ressources/situationsFamiliale"
import EntreprisesResource from "./ressources/entreprises"
import CategoriesSocioProResource from "./ressources/categoriesSocioPro"
import MyRightsResource from "./ressources/myrights"
import UserDetailsResource from "./ressources/userDetails"
import ProfilsResource from "./ressources/profils"
import FonctionsResource from "./ressources/fonctions"
import IdentitiesResource from "./ressources/identities"
import DroitsResource from "./ressources/droits"
import HorairesRessource from "./ressources/horaires"
import ActionsCollectivesResource from "./ressources/actionsCollectives"
import ActionsCollectivesProjetResource from "./ressources/actionsCollectivesProjet"
import DossiersRessource from "./ressources/dossiers"
import TauxIPPsResource from "./ressources/tauxIPPs"
import CategoriesInvaliditesResource from "./ressources/categoriesInvalidites"
import TempsTravailResource from "./ressources/tempsTravails"
import SituationProfessionnellesRessource from "./ressources/situationProfessionnelle"
import TypesInterventionCollectiveRessource from "./ressources/typesInterventionCollective"
import NaturesInterventionCollectiveRessource from "./ressources/naturesInterventionCollective"
import PartiesPrenantesRessource from "./ressources/partiesPrenantes"
import ProjetsResource from "./ressources/projets"
import ThemesRessources from "./ressources/themes"
import TypesInterventionCollectiveProjetRessource from "./ressources/typesInterventionCollectiveProjet"
import SecteursActivitesRessource from "./ressources/secteursActivites"
import RessifnetSecteursActivitesRessource from "./ressources/ressifnet/secteursActivites"
import DaisySecteursActivitesRessource from "./ressources/daisy/secteursActivites"
import EquipesResource from "./ressources/equipes"
import NotesResource from "./ressources/notes"
import RessifnetEntreprisesResource from "./ressources/ressifnet/entreprises"
import DaisyEntreprisesResource from "./ressources/daisy/entreprises"
import UtilisateursRessource from "./ressources/utilisateurs"
import PartenairesRessource from "./ressources/partenaires"
import FournisseursRessource from './ressources/fournisseurs'
import ContactsRessource from "./ressources/contacts"
import ParametresRessources from "./ressources/parametres"
import FichierResource from "./ressources/fichiers"
import InformationsAdditionnellesRessources from "./ressources/informationsAdditionnelles"
import FeelingRessource from "./ressources/feeling"
import CompetencesResource from "./ressources/competences"
import GroupesResource from "./ressources/groupes"
import AvisRessource from "./ressources/avis"
import MyProfileResource from './ressources/myprofile';
import TrombinoscopeResource from './ressources/trombinoscope';
import ExportDonneesResource from "./ressources/exportDonnees"
import PreferencesUtilisateurResource from "./ressources/preferencesUtilisateur"
import StructuresResource from "./ressources/structures"
import CreneauxResource from "./ressources/creneaux"
import TypesCreneauResource from "./ressources/typesCreneau"
import ExportPlanningAdherentModelesResource from "./ressources/exportPlanningAdherentModeles"
import SimulationsResource from "./ressources/simulations"
import PlanningsUtilsResource from "./ressources/planningsUtils"
import RessifnetFasttFonctionsResource from "./ressources/ressifnetFastt/fonction"
import TypesPartenaireFASTTRessources from "./ressources/fastt/typesPartenaireFASTT"
import PartenairesFASTTRessources from "./ressources/fastt/partenairesFASTT"
import ChangelogTypesResource from "./ressources/changelogTypes"
import ChangelogsResource from "./ressources/changelogs"
import TenantsResource from "./ressources/tenants"
import UnreadChangelogsResource from "./ressources/unreadChangelogs"
import BIAccesResource from "./ressources/biAcces"
import BIRapporsResource from "./ressources/biRapports"
import FichesIncidentResource from "./ressources/fichesIncident"
import ProcessusResource from "./ressources/processus"
import CodesNafRessource from "./ressources/codesNaf"


// eslint-disable-next-line no-use-before-define
const myRessifApi: MyRessifApiType = {
  typeContrats: new TypeContratsResource(),
  domaines: new DomainesResource(),
  situationsFamiliale: new SituationsFamilialeResource(),
  entreprises: new EntreprisesResource(),
  categoriesSocioPro: new CategoriesSocioProResource(),
  myrights: new MyRightsResource(),
  userDetails: new UserDetailsResource(),
  profils : new ProfilsResource(),
  fonctions: new FonctionsResource(),
  identities: new IdentitiesResource(),
  horaires: new HorairesRessource(),
  droits: new DroitsResource(),
  dossiers: new DossiersRessource(),
  tauxIPPs: new TauxIPPsResource(),
  categoriesInvalidites: new CategoriesInvaliditesResource(),
  tempsTravail: new TempsTravailResource(),
  actionsCollectives: new ActionsCollectivesResource(),
  actionsCollectivesProjet: new ActionsCollectivesProjetResource(),
  typesInterventionCollective: new TypesInterventionCollectiveRessource(),
  typesInterventionCollectiveProjet: new TypesInterventionCollectiveProjetRessource(),
  naturesInterventionCollective: new NaturesInterventionCollectiveRessource(),
  partiesPrenantes: new PartiesPrenantesRessource(),
  situationProfessionnelles: new SituationProfessionnellesRessource(),
  projets: new ProjetsResource(),
  themes: new ThemesRessources(),
  secteursActivites: new SecteursActivitesRessource(),
  equipes: new EquipesResource(),
  notes: new NotesResource(),
  utilisateurs: new UtilisateursRessource(),
  partenaires: new PartenairesRessource(),
  fournisseurs: new FournisseursRessource(),
  contacts: new ContactsRessource(),
  parametres: new ParametresRessources(),
  fichiers: new FichierResource(),
  informationsAdditionnelles: new InformationsAdditionnellesRessources(),
  feeling: new FeelingRessource(),
  competences: new CompetencesResource(),
  groupes: new GroupesResource(),
  avis: new AvisRessource(),
  myprofile: new MyProfileResource(),
  trombinoscope: new TrombinoscopeResource(),
  exportDonnees: new ExportDonneesResource(),
  preferencesUtilisateur : new PreferencesUtilisateurResource(),
  structures: new StructuresResource(),
  creneaux: new CreneauxResource(),
  typesCreneau: new TypesCreneauResource(),
  exportPlanningAdherentModeles: new ExportPlanningAdherentModelesResource(),
  simulations: new SimulationsResource(),
  planningsUtils: new PlanningsUtilsResource(),
  typesPartenaireFASTT: new TypesPartenaireFASTTRessources(),
  partenairesFASTT: new PartenairesFASTTRessources(),
  changelogs: new ChangelogsResource(),
  changelogTypes: new ChangelogTypesResource(),
  tenants: new TenantsResource(),
  unreadChangelogs: new UnreadChangelogsResource(),
  fichesIncident: new FichesIncidentResource(),
  processus: new ProcessusResource(),
  biAcces: new BIAccesResource(),
  biRapports: new BIRapporsResource(),
  codesNaf: new CodesNafRessource(),
}
export interface MyRessifApiType {
  typeContrats: TypeContratsResource,
  domaines: DomainesResource,
  situationsFamiliale: SituationsFamilialeResource,
  entreprises: EntreprisesResource,
  categoriesSocioPro: CategoriesSocioProResource,
  myrights: MyRightsResource,
  userDetails: UserDetailsResource,
  profils : ProfilsResource,
  fonctions: FonctionsResource,
  identities: IdentitiesResource,
  horaires: HorairesRessource,
  droits: DroitsResource,
  dossiers: DossiersRessource,
  tauxIPPs: TauxIPPsResource,
  categoriesInvalidites: CategoriesInvaliditesResource,
  tempsTravail: TempsTravailResource,
  actionsCollectives: ActionsCollectivesResource,
  actionsCollectivesProjet: ActionsCollectivesProjetResource,
  typesInterventionCollective: TypesInterventionCollectiveRessource,
  typesInterventionCollectiveProjet: TypesInterventionCollectiveProjetRessource,
  naturesInterventionCollective: NaturesInterventionCollectiveRessource,
  partiesPrenantes: PartiesPrenantesRessource,
  situationProfessionnelles: SituationProfessionnellesRessource,
  projets: ProjetsResource,
  themes: ThemesRessources,
  secteursActivites: SecteursActivitesRessource,
  equipes: EquipesResource,
  notes: NotesResource,
  utilisateurs: UtilisateursRessource,
  partenaires: PartenairesRessource,
  fournisseurs: FournisseursRessource,
  contacts : ContactsRessource,
  parametres: ParametresRessources,
  fichiers : FichierResource,
  informationsAdditionnelles: InformationsAdditionnellesRessources,
  feeling : FeelingRessource,
  competences : CompetencesResource,
  groupes : GroupesResource,
  avis: AvisRessource,
  myprofile: MyProfileResource,
  trombinoscope: TrombinoscopeResource,
  exportDonnees: ExportDonneesResource,
  preferencesUtilisateur : PreferencesUtilisateurResource,
  structures: StructuresResource,
  creneaux: CreneauxResource,
  typesCreneau: TypesCreneauResource,
  exportPlanningAdherentModeles: ExportPlanningAdherentModelesResource,
  simulations: SimulationsResource,
  planningsUtils: PlanningsUtilsResource,
  typesPartenaireFASTT: TypesPartenaireFASTTRessources,
  partenairesFASTT: PartenairesFASTTRessources,
  changelogTypes: ChangelogTypesResource,
  changelogs: ChangelogsResource,
  tenants: TenantsResource,
  unreadChangelogs: UnreadChangelogsResource,
  fichesIncident: FichesIncidentResource,
  processus: ProcessusResource,
  biAcces: BIAccesResource,
  biRapports: BIRapporsResource
  codesNaf: CodesNafRessource
}

const ressifnetApi: RessifnetApiType = {
  secteursActivite: new RessifnetSecteursActivitesRessource(),
  entreprises: new RessifnetEntreprisesResource()
}
export interface RessifnetApiType {
  secteursActivite: RessifnetSecteursActivitesRessource,
  entreprises: RessifnetEntreprisesResource
}


const daisyApi: DaisyApiType = {
  secteursActivite: new DaisySecteursActivitesRessource(),
  entreprises: new DaisyEntreprisesResource()
}
export interface DaisyApiType {
  secteursActivite: RessifnetSecteursActivitesRessource,
  entreprises: RessifnetEntreprisesResource
}

const ressifnetFasttApi: RessifnetFasttApiType = {
  fonctions: new RessifnetFasttFonctionsResource()
}
export interface RessifnetFasttApiType {
  fonctions: RessifnetFasttFonctionsResource
}

export interface AppApiTypes {
  ressifnet: RessifnetApiType,
  daisy: DaisyApiType,
  myressif: MyRessifApiType,
  ressifnetFastt: RessifnetFasttApiType
}

const appApiTypes: AppApiTypes = {
  ressifnet: ressifnetApi,
  daisy: daisyApi,
  myressif: myRessifApi,
  ressifnetFastt: ressifnetFasttApi
}

export default appApiTypes
