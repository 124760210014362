import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { TypeCreneau } from '../models/creneaux/typeCreneau';
import { CreateSousTypeCreneauModel, SousTypeCreneau } from '../models/typesCreneau/sousTypeCreneau';


const resource = 'typesCreneau'

export default class TypesCreneauResource {
  public async paginatedList(search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<TypeCreneau>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<TypeCreneau> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: TypeCreneau) : Promise<TypeCreneau> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : TypeCreneau) : Promise<TypeCreneau> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<TypeCreneau> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async getListSousTypesCreneauByTypeCreneauId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    orderBy?: string,
    orderByDescending?: boolean
  ): Promise<PaginatedList<SousTypeCreneau>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/sousTypesCreneau`, {
      params: params,
    });
    return response.data;
  }

  public async getSousTypeCreneauById(id: string, sousTypeCreneauId: string): Promise<SousTypeCreneau> {
    const response = await api.get(`${resource}/${id}/sousTypesCreneau/${sousTypeCreneauId}`);
    return response.data;
  }

  public async createSousTypeCreneauOnTypeCreneau(
    id: string,
    data: CreateSousTypeCreneauModel
  ): Promise<SousTypeCreneau> {
    const response = await api.post(`${resource}/${id}/sousTypesCreneau`, data);
    return response.data;
  }

  public async updateSousTypeCreneauTypeCreneau(
    id: string,
    sousTypeCreneauId: string,
    data: SousTypeCreneau
  ): Promise<SousTypeCreneau> {
    const response = await api.put(
      `${resource}/${id}/sousTypesCreneau/${sousTypeCreneauId}`,
      data
    );
    return response.data;
  }

  public async deleteSousTypeCreneauTypeCreneau(
    typeCreneauId: string,
    sousTypeCreneauId: string
  ): Promise<SousTypeCreneau> {
    const response = await api.delete(
      `${resource}/${typeCreneauId}/sousTypesCreneau/${sousTypeCreneauId}`
    );
    return response.data;
  }
}
