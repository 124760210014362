import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { CreateDuplicationModel, CreateDuplicationSalarieModel, Creneau, CreneauCreateModel, Duplication, EditPeriodiciteModel } from '../models/creneaux/creneau';


const resource = 'creneaux'

export default class CreneauxResource {
  public async paginatedList(
    startDate: string, 
    endDate: string, 
    entrepriseId: string, 
    salarieId: string, 
    filterSalariesIds: string[], 
    filterEntreprisesIds: string[], 
    isForSystem?: boolean,
    typesCreneauIds?: string[],
    sitesIds?: string[],
    prestationId?: string,
    annee?: number,
    moisDebut?: number,
    moisFin?: number,
    search?: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean,
    simulationId?: string
  ) : Promise<PaginatedList<Creneau>> {
    const data = {
      startDate: startDate,
      endDate: endDate,
      entrepriseId: entrepriseId,
      salarieId: salarieId,
      filterSalariesIds: filterSalariesIds,
      filterEntreprisesIds: filterEntreprisesIds,
      isForSystem: isForSystem,
      pageNumber: pagenumber,
      pageSize: pagesize,
      orderBy: orderBy,
      orderByDescending: orderByDescending,
      typesCreneauIds: typesCreneauIds,
      sitesIds: sitesIds,
      prestationId: prestationId,
      annee: annee,
      moisDebut: moisDebut,
      moisFin: moisFin,
      search: search,
      simulationId: simulationId
    }

    const response = await api.post(`${resource}/search`, data )
    return response.data
  }

  public async getById(id: string) : Promise<Creneau> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: CreneauCreateModel) : Promise<Creneau> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Creneau) : Promise<Creneau> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Creneau> {

    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async updatePeriodicite(id: string, periodiciteId: string, data : EditPeriodiciteModel) : Promise<EditPeriodiciteModel> {
    const response = await api.put(`${resource}/${id}/periodicites/${periodiciteId}`, {periodicite: data})
    return response.data
  }

  public async deleteCreneauxByPeriodicite(id: string, periodiciteId: string) : Promise<Creneau> {
    const response = await api.delete(`${resource}/${id}/periodicites/${periodiciteId}`)
    return response.data
  }

  public async exportPlanningAdherent (
    startDate: string, 
    endDate: string, 
    entrepriseId: string, 
    salarieId: string, 
    filterSalariesIds: string[], 
    filterEntreprisesIds: string[], 
    isForSystem?: boolean,
    typesCreneauIds?: string[],
    sitesIds?: string[],
    prestationId?: string,
    modeleId?: string,
    annee?: number,
    moisDebut?: number,
    moisFin?: number,
    search?: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean
  ) : Promise<any> {
    const data = {
      startDate: startDate,
      endDate: endDate,
      entrepriseId: entrepriseId,
      salarieId: salarieId,
      filterSalariesIds: filterSalariesIds,
      filterEntreprisesIds: filterEntreprisesIds,
      isForSystem: isForSystem,
      pageNumber: pagenumber,
      pageSize: pagesize,
      orderBy: orderBy,
      orderByDescending: orderByDescending,
      typesCreneauIds: typesCreneauIds,
      sitesIds: sitesIds,
      prestationId: prestationId,
      modeleId: modeleId,
      annee: annee,
      moisDebut: moisDebut,
      moisFin: moisFin,
      search: search
    }

    const response = await api.post(`${resource}/exportPlanningAdherent`, data, { responseType: 'arraybuffer', timeout: 300000 } )
    return response.data
  }

  public async exportPlanningExcel (
    startDate: string, 
    endDate: string, 
    entrepriseId: string, 
    salarieId: string, 
    filterSalariesIds: string[], 
    filterEntreprisesIds: string[], 
    isForSystem?: boolean,
    typesCreneauIds?: string[],
    sitesIds?: string[],
    prestationId?: string,
    modeleId?: string,
    annee?: number,
    moisDebut?: number,
    moisFin?: number,
    search?: string,
    pagenumber?: number,
    pagesize?: number,
    orderBy?: string,
    orderByDescending?: boolean
  ) : Promise<any> {
    const data = {
      startDate: startDate,
      endDate: endDate,
      entrepriseId: entrepriseId,
      salarieId: salarieId,
      filterSalariesIds: filterSalariesIds,
      filterEntreprisesIds: filterEntreprisesIds,
      isForSystem: isForSystem,
      pageNumber: pagenumber,
      pageSize: pagesize,
      orderBy: orderBy,
      orderByDescending: orderByDescending,
      typesCreneauIds: typesCreneauIds,
      sitesIds: sitesIds,
      prestationId: prestationId,
      modeleId: modeleId,
      annee: annee,
      moisDebut: moisDebut,
      moisFin: moisFin,
      search: search
    }

    const response = await api.post(`${resource}/exportPlanningExcel`, data, { responseType: 'arraybuffer', timeout: 300000 } )
    return response.data
  }

  public async postDuplication(data: CreateDuplicationModel) : Promise<Duplication> {
    const response = await api.post(`${resource}/${data.creneauId}/Duplications`, data)
    return response.data
  }

  public async deleteDuplication(creneauId: string, duplicationId: string) : Promise<Duplication> {
    const response = await api.delete(`${resource}/${creneauId}/Duplications/${duplicationId}`)
    return response.data
  }
}
