import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Domaine } from '../models/options/domaines/domaine'

const resource = 'Domaines'

export default class DomainesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search: string = "", actifOnly: boolean = false, includeSubDomains: boolean = false) : Promise<PaginatedList<Domaine>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search) params.append('Search', search.toString()); 
    if (actifOnly) params.append('ActifOnly', actifOnly.toString()); 
    if (includeSubDomains) params.append('IncludeSubdomains', includeSubDomains.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async post(data : Domaine) : Promise<Domaine> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Domaine> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async put(id : string, data : Domaine) : Promise<Domaine> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }
}
