import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { CategorieInvalidite } from '../models/dossiers/categorieInvalidite'

const resource = 'CategoriesInvalidites'

export default class CategoriesInvaliditesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifonly?: boolean) : Promise<PaginatedList<CategorieInvalidite>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifonly) params.append('ActifOnly', actifonly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
