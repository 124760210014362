export default [
    {
      path: '/statistiques-generales',
      name: 'search-statistiques-generales',
      component: () => import('@/views/statistiques/generales/Search.vue'),
      meta: {
        pageTitle: 'Statistiques générales',
        breadcrumb: [
          {
            text: 'Statistiques générales',
            active: true,
          },
        ],
        resource: 'ACL',
        action: 'read',
      },
    },
    {
        path: '/statistiques-crl',
        name: 'search-statistiques-crl',
        component: () => import('@/views/statistiques/crl/Search.vue'),
        meta: {
          pageTitle: 'Statistiques CRL',
          breadcrumb: [
            {
              text: 'Statistiques CRL',
              active: true,
            },
          ],
          resource: 'ACL',
          action: 'read',
        },
      },
      {
          path: '/statistiques-dossiers-instruits',
          name: 'search-statistiques-dossiers-instruits',
          component: () => import('@/views/statistiques/dossiers-instruits/Search.vue'),
          meta: {
            pageTitle: 'Statistiques des dossiers instruits',
            breadcrumb: [
              {
                text: 'Statistiques des dossiers instruits',
                active: true,
              },
            ],
            resource: 'ACL',
            action: 'read',
          },
        },
        {
            path: '/statistiques-accompagnement-collectif',
            name: 'search-statistiques-accompagnement-collectif',
            component: () => import('@/views/statistiques/accompagnement-collectif/Search.vue'),
            meta: {
              pageTitle: 'Statistiques de l\'accompagnement collectif',
              breadcrumb: [
                {
                  text: 'Statistiques de l\'accompagnement collectif',
                  active: true,
                },
              ],
              resource: 'ACL',
              action: 'read',
            },
          },
  ]
    