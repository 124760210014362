import { VueConstructor } from 'vue/types/umd'

export default {
  install (Vue: VueConstructor) {
    Vue.filter('formatDate', function(value: string) {
        return new Date(value).toLocaleDateString()
    })
    
    Vue.filter('formatDateWithTime', function(value: string) {
      const padL = (nr: any, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
      const dt = new Date(value);

      return `${
          padL(dt.getDate())}/${
          padL(dt.getMonth()+1)}/${
          dt.getFullYear()} à ${
          padL(dt.getHours())}:${
          padL(dt.getMinutes())}`;
    })
    
  }
}