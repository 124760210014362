import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import administration from './routes/administration'
import adherents from './routes/adherents'
import statistiques from './routes/statistiques'
import authService from '@/auth/auth.service'
import utilisateurs from './routes/utilisateurs'
import partenaires from './routes/partenaires'
import partenairesFASTT from './routes/partenairesFASTT'
import fournisseurs from './routes/fournisseurs'
import equipes from './routes/equipes'
import competences from './routes/competences'
import trombinoscope from './routes/trombinoscope'
import groupes from './routes/groupes'
import gestionAvis from './routes/gestionAvis'
import exportdonnee from './routes/export'
import preferencesUtilisateur from './routes/preferences-utilisateur'
import plannings from './routes/plannings'
import fichesIncident from './routes/fiches-incident'
import processus from './routes/processus'
import changelogs from './routes/changelogs'
import biAcces from './routes/biAcces'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'presentation' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...administration,
    ...adherents,
    ...utilisateurs,
    ...partenaires,
    ...partenairesFASTT,
    ...fournisseurs,
    ...statistiques,
    ...equipes,
    ...competences,
    ...trombinoscope,
    ...exportdonnee,
    ...groupes,
    ...gestionAvis,
    ...preferencesUtilisateur,
    ...plannings,
    ...fichesIncident,
    ...processus,
    ...changelogs,
    ...biAcces,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const user = await authService.getUser()
  const tokenExpired = await authService.willTokenExpire()
  const isLoggedIn = user && user.access_token && !tokenExpired
  if (
    !isLoggedIn
    && to.name !== 'misc-not-authorized'
    && to.name !== 'callback'
    && to.name !== 'silent-renew'
    && to.name !== 'presentation'
    && to.name !== 'confirmAccount'
    && to.name !== 'confirmEmail'
    && to.name !== 'resetPassword'
  ) {
    authService.tryLogin()
  } else if (!isLoggedIn) {
    return next()
  } else {
    // const userData = getUserData()

    if (to.name === 'presentation') {
      next({ name: 'home'})
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next({ name: 'home'})
    }

    if(canNavigate(to)){
      return next()
    }

    return next({ name: 'misc-not-authorized' })
  }

  return null
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
