export default [
  {
    path: '/preferences-utilisateur',
    name: 'preferences-utilisateur',
    component: () => import('@/views/preferences-utilisateur/Index.vue'),
    meta: {
      pageTitle: 'Préferences utilisateur',
      breadcrumb: [
        {
          text: 'Préferences utilisateur',
          active: true,
        },
      ],
      // rights: [
      //   'STATISTIQUES'
      // ],
      // notFonctions: [
      //   'CRL', 'RHU'
      // ]
    },
  },
]