export default [
    {
      path: "/export",
      name: "export",
      component: () => import('@/views/export/Index.vue'),
      meta: {
        pageTitle: 'Export',
        breadcrumb: [
          {
            text: 'Export',
            active: true,
          },
        ],
        rights: [
          'EXPORTS:read'
        ]
      }
    },
]