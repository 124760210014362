import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Note, UpdateNoteModel } from '../models/dossiers/note'

const baseResource = 'Dossiers'
const resource = 'Notes'

export default class NotesResource {
  public async paginatedList(dossierId: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Note>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${baseResource}/${dossierId}/${resource}`, { params: params } )
    return response.data
  }

  public async post(dossierId: string, data : Note) : Promise<Note> {
    const response = await api.post(`${baseResource}/${dossierId}/${resource}`, data)
    return response.data
  }

  public async delete(dossierId: string, id : string) : Promise<Note> {
    const response = await api.delete(`${baseResource}/${dossierId}/${resource}/${id}`)
    return response.data
  }

  public async put(dossierId : string, id: string, data : UpdateNoteModel) : Promise<Note> {
    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, data)
    return response.data
  }
}
