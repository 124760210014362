export default [
  {
    path: '/partenaires',
    name: 'partenaires',
    component: () => import('@/views/partenaires/Index.vue'),
    meta: {
      pageTitle: 'Gestion des partenaires',
      breadcrumb: [
        {
          text: 'Gestion des partenaires',
          active: true,
        },
      ],
      rights: [
        'PARTENAIRES:read'
      ]
    },
  },
  {
    path: '/partenaires/details/:id',
    name: 'details-partenaires',
    component: () => import('@/views/partenaires/Details.vue'),
    meta: {
      pageTitle: 'Détails des partenaires',
      breadcrumb: [
        {
          text: 'Détails des partenaires',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'PARTENAIRES:read'
      ]
    },
  }
]