import api from '@/libs/axios'
import { ShortTenant } from '../models/tenants/tenant';


const resource = 'tenants'

export default class TenantsResource {
  public async list() : Promise<ShortTenant[]> {
    const response = await api.get(resource)

    return response.data
  }
}
