import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import {
  UtilisateurTrombinoscope
} from "../models/utilisateurs/utilisateur";

const resource = "Trombinoscope";

export default class TrombinoscopeResource {
  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    searchBy?: string,
    orderBy?: string,
    orderByDescending?: boolean,
    equipeId?: string,
  ): Promise<PaginatedList<UtilisateurTrombinoscope>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search.toString());
    if (searchBy && searchBy.length) params.append("SearchBy", searchBy.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending) params.append("OrderByDescending", orderByDescending.toString());
    if (equipeId) params.append("EquipeId", equipeId.toString());

    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }
}
