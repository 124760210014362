export default [
  {
    path: "/competences",
    name: "competences",
    component: () => import('@/views/competences/Index.vue'),
    meta: {
      pageTitle: 'Gestion des compétences',
      breadcrumb: [
        {
          text: 'Gestion des compétences',
          active: true,
        },
      ],
      rights: [
        'INACCESSIBLE:read'
      ]
    },
  },
  {
    path: '/competences/details/:id',
      name: 'details-competences',
      component: () => import('@/views/competences/DetailsCompetence.vue'),
      meta: {
        pageTitle: 'Détails de la compétence',
        breadcrumb: [
          {
            text: 'Détails de la compétence',
            active: true,
          },
          {
            text: 'Détails'
          }
        ],
        rights: [
          'INACCESSIBLE:read'
        ]
      },
  }
]