import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import {
  CreateFicheIncidentModel,
  FicheIncident,
  FicheIncidentStatistiques,
  FichesIncidentSearchParams,
  UpdateFicheIncidentModel,
} from "../models/fichesIncident/ficheIncident";

const resource = "FichesIncident";

export default class FichesIncidentResource {
  public async searchPaginatedList(
    params: FichesIncidentSearchParams
  ): Promise<PaginatedList<FicheIncident>> {
    const response = await api.post(`${resource}/search`, params);
    return response.data;
  }

  public async exportFichesIncident(
    params: FichesIncidentSearchParams
  ): Promise<PaginatedList<FicheIncident>> {
    const response = await api.post(`${resource}/export`, params, { responseType: 'arraybuffer', timeout: 300000 } );
    console.log("test", response)
    return response.data;
  }

  public async getById(id: string): Promise<FicheIncident> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async getStatistiques(id: string): Promise<FicheIncidentStatistiques> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/statistiques`, { params: params });
    return response.data;
  }

  public async post(data: CreateFicheIncidentModel): Promise<FicheIncident> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async update(
    id: string,
    data: UpdateFicheIncidentModel,
  ): Promise<string> {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  }

  public async delete(id: string): Promise<void> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }
}
