export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Callback.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/confirmAccount/:userId/:emailToken/:passwordToken',
    name: 'confirmAccount',
    component: () => import('@/views/ConfirmAccount.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/:userId/confirmEmail/:emailToken',
    name: 'confirmEmail',
    component: () => import('@/views/ConfirmEmail.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/:userId/resetPassword/:passwordToken',
    name: 'resetPassword',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/silent-renew',
    name: 'silent-renew',
    component: () => import('@/views/SilentRenew.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: () => import('@/views/pages/Presentation.vue'),
    meta: {
      layout: 'full',
    },
  },
]
