import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Fonction } from '../models/authorization/fonctions/fonction'

const resource = 'Fonctions'

export default class FonctionsResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, includeDroits: boolean = false) : Promise<PaginatedList<Fonction>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if(includeDroits) params.append('IncludeDroits', includeDroits.toString());
    
    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getDroitsByFonctionId(id: string, includeDroits: boolean = true): Promise<Fonction> {
    const params = new URLSearchParams();
    params.append('IncludeDroits', includeDroits.toString());
    const response = await api.get(`${resource}/`, { params: params })
    return response.data
  }

  public async post(data : Fonction) : Promise<Fonction> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Fonction> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async put(id : string, data : Fonction) : Promise<Fonction> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async updateDroits(id : string, data : any) : Promise<any> {
    const response = await api.put(`${resource}/${id}/Droits`, data)
    return response.data
  }

  public async postDroitFonction(id: string, droitId: string, flag: any) : Promise<Fonction> {
    const params = {
      id: id,
      droitId: droitId,
      flag: flag
    };
    const response = await api.post(`${resource}/${id}/Droits/${droitId}`, params)
    return response.data
  }

  public async deleteDroitFonction(id: string, droitId: string, flag: any) : Promise<Fonction> {
    const response = await api.delete(`${resource}/${id}/Droits/${droitId}/${flag}`)
    return response.data
  }

  public async listFonctionsByEntityType(entityType: string) : Promise<any> {
    const response = await api.get(`${resource}/entityType/${entityType}`)

    return response.data
  }
}
