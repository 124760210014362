export default [
    {
      path: '/groupes',
      name: 'groupes',
      component: () => import('@/views/groupes/Index.vue'),
      meta: {
        pageTitle: 'Gestion des groupes',
        breadcrumb: [
          {
            text: 'Gestion des groupes',
            active: true,
          },
        ],
        rights: [
          'GROUPES:read'
        ]
      },
    },
    {
      path: '/groupes/details/:id',
        name: 'details-groupes',
        component: () => import('@/views/groupes/DetailsGroupe.vue'),
        meta: {
          pageTitle: 'Détails du groupe',
          breadcrumb: [
            {
              text: 'Détails du groupe',
              active: true,
            },
            {
              text: 'Détails'
            }
          ],
          rights: [
            'GROUPES:read'
          ]
        },
    }  
]