import api from '@/libs/axios'
import { DossierCreateModel } from '../models/dossiers/dossier'
import { PaginatedList } from '../models/common/paginatedList'
import { Dossier } from '../models/dossiers/dossier'
import { SituationProfessionnelles } from '../models/options/situationProfessionnelles/situationProfessionnelle'

const baseResource = 'dossiers'
const resource = 'SituationProfessionnelles'

export default class SituationProfessionnellesRessource {
  public async get(dossierId: string ) : Promise<SituationProfessionnelles> {
    const response = await api.get(`${baseResource}/${dossierId}/${resource}`)
    return response.data
  }

  public async put(dossierId: string, id: string, data: Dossier) : Promise<SituationProfessionnelles> { 
    const response = await api.put(`${baseResource}/${dossierId}/${resource}/${id}`, data)
    return response.data
  }
  
}
