import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import DisplayIcon from '@/components/icons/DisplayIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(DisplayIcon.name, DisplayIcon)
Vue.component(EditIcon.name, EditIcon)
Vue.component(DeleteIcon.name, DeleteIcon)
