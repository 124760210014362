import api from '@/libs/axios'
import { Dossier } from '../models/dossiers/dossier'
import { PaginatedList } from '../models/common/paginatedList';
import { DossierCreateModel, DossierSearchParams } from '../models/dossiers/dossier'

const resource = 'Dossiers'

export default class DossiersRessource {
  public async get(id: string) : Promise<Dossier> { 
    const response = await api.get(`${resource}/${id}` )
    return response.data
  }

  public async getLasts() : Promise<PaginatedList<Dossier>> {
    const response = await api.get(`${resource}/lasts`)
    return response.data
  }

  public async search(data: DossierSearchParams) : Promise<PaginatedList<Dossier>> {
    const response = await api.post(`${resource}/search`, data)
    return response.data
  }

  public async post(data : DossierCreateModel) : Promise<DossierCreateModel> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async put(id: string, data: Dossier) : Promise<Dossier> { 
    console.log('put', data)
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }
}
