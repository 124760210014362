
















import { Component, Prop, Vue } from "vue-property-decorator";
import { VueSelect } from "vue-select";

@Component({
  components: {
    VueSelect
  },
  name: "searchable-vue-select",
})
export default class SearchableVueSelect extends Vue {
  @Prop({ required: true }) hasNextPage!: boolean
  @Prop({ required: false, default: false }) loadOnCreate!: boolean 
  observer: any = null
  
  search= ""
  pageSize = 10
  pageNumber = 1

  timeout: any

  created() {
    if(this.loadOnCreate) {
      this.$emit('load-next-page', {
        pageNumber: this.pageNumber, 
        pageSize: this.pageSize, 
        search: this.search,
        reset: true
      })
    }
  }
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  }

  searchChanged(query: string) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.pageNumber = 1
      this.search = query
      
      this.$emit('load-next-page', {
        pageNumber: this.pageNumber, 
        pageSize: this.pageSize, 
        search: this.search,
        reset: true
      })
    }, 1300)
  }


  async onOpen() {
    if (this.hasNextPage) {
      await this.$nextTick();
      this.observer.observe(this.$refs.load);
    }
  }
  
  onClose() {
    this.observer.disconnect();
  }

  async infiniteScroll([{ isIntersecting, target }] : any[]) {
    if (isIntersecting) {
      const ul = target.offsetParent;
      const scrollTop = target.offsetParent.scrollTop;
      this.pageNumber += 1;
      await this.$nextTick();

      this.$emit('load-next-page', {
        pageNumber: this.pageNumber, 
        pageSize: this.pageSize, 
        search: this.search,
        reset: false
      })
      ul.scrollTop = scrollTop;

    }
  }
}

