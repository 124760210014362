export default [
  {
    path: '/changelog-types',
    name: 'changelog-types',
    component: () => import('@/views/changelogs/changelogTypes/Index.vue'),
    meta: {
      pageTitle: 'Gestion des types de changelog',
      breadcrumb: [
        {
          text: 'Gestion des types de changelog',
          active: true,
        },
      ],
      rights: [
        'PARAMETRES:admin',
      ]
    },
  },
  {
    path: '/manage-changelogs',
    name: 'manage-changelogs',
    component: () => import('@/views/changelogs/manage-changelogs/Index.vue'),
    meta: {
      pageTitle: 'Gestion des changelogs',
      breadcrumb: [
        {
          text: 'Gestion des changelogs',
          active: true,
        },
      ],
      rights: [
        'PARAMETRES:admin',
      ]
    },
  },
  {
    path: '/changelogs',
    name: 'changelogs',
    component: () => import('@/views/changelogs/changelogs/Index.vue'),
    meta: {
      pageTitle: 'Changelogs',
      resource: 'ACL',
      action: 'read',
    },
  },
]

