import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { BIAcces } from '../models/biAcces/biAcces';

const resource = 'BIAcces'

export default class BIAccesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number) : Promise<PaginatedList<BIAcces>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<BIAcces> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: BIAcces) : Promise<BIAcces> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : BIAcces) : Promise<BIAcces> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<BIAcces> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
}
