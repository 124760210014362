import Vue from 'vue'

// axios
import axios from 'axios'
import appApiTypes from '@/api'
import authService from '@/auth/auth.service'
import router from '@/router'
import appSettings from '@/appSettings'

const axiosIns = axios.create({
  baseURL: appSettings.getApiURL(),
  // baseURL: process.env.VUE_APP_API_DOMAIN,
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  headers: {'Access-Control-Allow-Origin': '*'}
})

axiosIns.interceptors.request.use(async config => {
  const accessToken = await authService.getAccessToken()
  // eslint-disable-next-line no-param-reassign
  config.headers.common.Authorization = `Bearer ${accessToken}`
  return config
})

// eslint-disable-next-line prefer-arrow-callback
axiosIns.interceptors.response.use(async function (response) {
  return response
}, async function (error) {
  if (error.response.status === 403 || error.response.status === 401) {
    
    const accessToken = await authService.getAccessToken()
    const tokenExpired = await authService.willTokenExpire()
    
    if (!accessToken || tokenExpired) {
      authService.login()
    }

    router.push({ name: 'misc-not-authorized' })
  } else {
    return Promise.reject(error)
  }
})

axiosIns.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosIns.defaults.headers.get['Accepts'] = 'application/json'
axiosIns.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

const http = { ...appApiTypes, get: axiosIns.get, post: axiosIns.post }
Vue.prototype.$http = http

export default axiosIns
