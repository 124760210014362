import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Competence } from '../models/competences/competence';
import { MembreCompetence, MembreCompetenceCreateModel } from '../models/membresCompetences/membreCompetence';


const resource = 'Competences';

export default class CompetencesResource {
  /**
   * paginatedList
   * @param pagenumber : number
   * @param pagesize? : number
   * @returns : Promise<PaginatedList<Competence>>
   */
  public async paginatedList(pagenumber?: number, pagesize?: number): Promise<PaginatedList<Competence>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } );
    return response.data;
  }

  /**
   * getById
   * @param id : string
   * @returns : Promise<Competence>
   */
  public async getById(id: string) : Promise<Competence> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }  

  /**
   * post
   * @param data : Competence
   * @returns : Promise<Competence>
   */
  public async post(data: Competence) : Promise<Competence> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  /**
   * update
   * @param id : string
   * @param data : Competence
   * @returns : Promise<Competence>
   */
  public async update(id: string, data : Competence) : Promise<Competence> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  /**
   * delete
   * @param id : string
   * @returns : Promise<Competence>
   */
  public async delete(id : string) : Promise<Competence> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  /**
   * listMembres
   * @param id : string
   * @returns Promise<PaginatedList<MembreCompetence>>
   */
  public async listMembres(id : string) : Promise<PaginatedList<MembreCompetence>> {
    const response = await api.get(`${resource}/${id}/membres`)
    return response.data
  }


  /**
   * addMembre
   * @param competenceId: string
   * @param data: MembreCompetenceCreateModel
   * @returns: Promise<Competence>
   */
  public async addMembre(competenceId: string, data: MembreCompetenceCreateModel): Promise<Competence> {
    const response = await api.post(`${resource}/${competenceId}/membres`, data);
    console.log(response.data);
    return response.data;
  }

  /**
   * removeMembre
   * @param competenceId: string
   * @param membreId: string
   * @returns: Promise<Competence>
   */
   public async removeMembre(competenceId: string, membreId: string): Promise<Competence> {
    const response = await api.delete(`${resource}/${competenceId}/membres/${membreId}`);
    console.log(response.data);
    return response.data;
  }

  public async updateMembre(id: string, membreId: string, data: any) : Promise<void> {
    let formData = new FormData();
    formData.append('Note', data.note);
    formData.append('Categorie', data.categorie);
    const response = await api.put(`${resource}/${id}/membres/${membreId}`, formData);
    return response.data;
  }

  /**
   * getById
   * @param id : string
   * @returns : Promise<Competence>
   */
   public async getByMembreId(id: string, membreId: string) : Promise<Competence> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}/membres/${membreId}`)
    return response.data
  }
}