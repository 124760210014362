











































import { BButton, BModal, BFormTextarea } from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css';
import { Component, Vue } from "vue-property-decorator";
import { Avis } from "@/api/models/avis/avis";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BModal,
    BButton,
    VueSlider,
    BFormTextarea,
  },
})
export default class AvisModal extends Vue {
  avisUser: Avis = {
    avis: 0,
    commentaire: "",
  };

  value = 5;
  direction = "ltr";
  disabled = false;

  async created() {}

  async pushAvis(avisUser: Avis) {
    await this.$http.myressif.avis.updateAvis(avisUser).then(
      async (response: any) => {
        successAlert.fire({
          timer: 3500,
          title: "Merci !",
          text: "Bonne journée",
        });
        this.$bvModal.hide("avis-modal");
      },
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }
}
