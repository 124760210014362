
















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "delete-icon"
})
export default class DeleteIcon extends Vue {
  
}
