export default [
  {
    path: "/processus",
    name: "processus",
    component: () => import("@/views/fichesIncident/processus/Index.vue"),
    meta: {
      pageTitle: "Gestion des processus",
      breadcrumb: [
        {
          text: "Gestion des processus",
          active: true,
        },
      ],
      rights: ["FI_PROCESSUS:read"],
    },
  },
];
