import api from '@/libs/axios'
import { CreateDuplicationSalarieModel, DeleteCreneauxPeriodeModel } from '../models/creneaux/creneau'

const resource = 'PlanningsUtils'

export default class PlanningsUtilsResource {
  public async createDuplicationSalarie(data: CreateDuplicationSalarieModel) : Promise<any> {
    const response = await api.post(`${resource}/DuplicationsSalaries/${data.fromUserId}`, data)

    return response.data
  }

  public async deleteCreneauxPeriode(data: DeleteCreneauxPeriodeModel) : Promise<any> {
    const response = await api.post(`${resource}/DeleteCreneauxPeriode`, data)

    return response.data
  }
}
