class AppSettings {
  private values: any[] = [
    {
      VUE_APP_DOMAIN: "localhost:8080",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"http://localhost:8080/",
      VUE_APP_API_DOMAIN:"https://localhost:57679/",
      VUE_APP_TENANT_ID: "405574b1-8674-493a-9dc8-919f5686d915",
      VUE_APP_TENANT_NAME: "LocalHost",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif-sstrn.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-sstrn.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif-sstrn.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://myressifapi-sstrn.ressif-dev.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif-acist.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-acist.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif-acist.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://myressifapi-acist.ressif-dev.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "ACIST",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif-ressif.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-ressif.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif-ressif.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://myressifapi-ressif.ressif-dev.com/",
      VUE_APP_TENANT_ID: "fade294a-b9a9-4bd6-a803-c067d2bd8ef3",
      VUE_APP_TENANT_NAME: "RESSIF",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: true
    },
    {
      VUE_APP_DOMAIN: "myressif-focsie.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-focsie.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif-focsie.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://myressifapi-focsie.ressif-dev.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "FOCSIE",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif-ies.ressif-dev.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity-ies.ressif-dev.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif-ies.ressif-dev.com/",
      VUE_APP_API_DOMAIN:"https://myressifapi-ies.ressif-dev.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "IES",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.ressif.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.ressif.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.ressif.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.ressif.ressif-apps.com/",
      VUE_APP_TENANT_ID: "fade294a-b9a9-4bd6-a803-c067d2bd8ef3",
      VUE_APP_TENANT_NAME: "RESSIF",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: true
    },
    {
      VUE_APP_DOMAIN: "myressif.sstrn.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.sstrn.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.sstrn.ressif-apps.com/",
      VUE_APP_TENANT_ID: "9f66153a-1411-482f-9c63-2fc4cb595634",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.ies.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.ies.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.ies.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.ies.ressif-apps.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "IES",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.focsie.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.focsie.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.focsie.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.focsie.ressif-apps.com/",
      VUE_APP_TENANT_ID: "23ec642d-e912-4364-a3c4-9b9ef4a436ca",
      VUE_APP_TENANT_NAME: "FOCSIE",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.acist.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.acist.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.acist.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.acist.ressif-apps.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "ACIST",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.actis.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.actis.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.actis.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.actis.ressif-apps.com/",
      VUE_APP_TENANT_ID: "c341c9a0-e03a-4b11-be87-d8bbfea82399",
      VUE_APP_TENANT_NAME: "ACTIS",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.asia.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.asia.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.asia.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.asia.ressif-apps.com/",
      VUE_APP_TENANT_ID: "6fa4b9be-9411-48e6-879c-53505fae2a8c",
      VUE_APP_TENANT_NAME: "ASIA",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.coreso.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.coreso.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.coreso.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.coreso.ressif-apps.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "CORESO",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.csiereso.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.csiereso.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.csiereso.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.csiereso.ressif-apps.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "CSIERESO",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.focsiecentre.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.focsiecentre.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.focsiecentre.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.focsiecentre.ressif-apps.com/",
      VUE_APP_TENANT_ID: "f0fbeacb-7e9f-47d0-a412-7677570f4ea5",
      VUE_APP_TENANT_NAME: "FOCSIE CENTRE",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.sis.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sis.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.sis.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.sis.ressif-apps.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "SIS",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.socialinter.ressif-apps.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.socialinter.ressif-apps.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.socialinter.ressif-apps.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.socialinter.ressif-apps.com/",
      VUE_APP_TENANT_ID: "4c001c66-df48-4bdb-bb9d-e1151b16a6a8",
      VUE_APP_TENANT_NAME: "SOCIAL INTER",
      VUE_APP_CLIENT_NAME: "vuejs_code_client",
      ALLOW_ADD_RESSIFNET_FASTT: false,
      IS_RESSIF_TENANT: false
    },

    
    {
      VUE_APP_DOMAIN: "myressif.ressif.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.ressif.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.ressif.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.ressif.ressif-staging.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "RESSIF",
      VUE_APP_CLIENT_NAME: "myressif",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: true
    },
    {
      VUE_APP_DOMAIN: "myressif.ies.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.ies.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.ies.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.ies.ressif-staging.com/",
      VUE_APP_TENANT_ID: "",
      VUE_APP_TENANT_NAME: "IES",
      VUE_APP_CLIENT_NAME: "myressif",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
    {
      VUE_APP_DOMAIN: "myressif.sstrn.ressif-staging.com",
      VUE_APP_IDENTITY_DOMAIN:"https://identity.sstrn.ressif-staging.com/",
      VUE_APP_FRONT_DOMAIN:"https://myressif.sstrn.ressif-staging.com/",
      VUE_APP_API_DOMAIN:"https://myressif-api.sstrn.ressif-staging.com/",
      VUE_APP_TENANT_ID: "9f66153a-1411-482f-9c63-2fc4cb595634",
      VUE_APP_TENANT_NAME: "SSTRN",
      VUE_APP_CLIENT_NAME: "myressif",
      ALLOW_ADD_RESSIFNET_FASTT: true,
      IS_RESSIF_TENANT: false
    },
  ]

  public getApiURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_API_DOMAIN
    else
      return "ERROR-URL"
  }
  
  public getFrontURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_FRONT_DOMAIN
    else
      return "ERROR-URL"
  }

  public getClientName() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_CLIENT_NAME
    else
      return 'vuejs_code_client'
  }

  public getIdentityURL() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_IDENTITY_DOMAIN
    else
      return "ERROR-URL"
  }

  public getTenantName(){
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_TENANT_NAME
    else
      return "ERROR-NAME"
  }

  public getTenantId() {
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.VUE_APP_TENANT_ID
    else
      return "ERROR-ID"
  }

  public getAllowAddRessifnetFastt(){
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.ALLOW_ADD_RESSIFNET_FASTT
    else
      return "ERROR-RESSIFNET_FASTT"
  }

  public getIsTenantRESSIF(){
    const host = window.location.host;
    const settings = this.values.filter(x => x.VUE_APP_DOMAIN === host)[0]
    if (settings)
      return settings.IS_RESSIF_TENANT
    else
      return false
  }
}
const appS = new AppSettings()
export default appS