export default [
    {
      path: '/bi-acces',
      name: 'bi-acces',
      component: () => import('@/views/biAcces/Index.vue'),
      meta: {
        pageTitle: 'Gestion des accès BI',
        breadcrumb: [
          {
            text: 'Gestion des accès BI',
            active: true,
          },
        ],
        rights: [
          'ACCES_BI:read'
        ]
      },
    },
]