import api from "@/libs/axios";
import { PaginatedList } from "../models/common/paginatedList";
import { CodeNaf } from "../models/codesNaf/codeNaf";

const resource = "CodesNaf";

export default class CodesNafRessource {
  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    search?: string,
  ): Promise<PaginatedList<CodeNaf>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
   
    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }
}
