import api from "@/libs/axios";
import { Profil } from "../models/authorization/profils/profil";
import { PaginatedList } from "../models/common/paginatedList";
import { Planning } from "../models/planning/planning";
import {
  DetailsUtilisateur,
  UtilisateurCreateModel,
  UtilisateurValidateAccountModel,
  UtilisateurValidateEmailModel,
  UtilisateurValidatePasswordModel,
  InfosSalariePlanning
} from "../models/utilisateurs/utilisateur";
import { Contrat, ContratCreateModel, ContratEditModel } from "../models/utilisateurs/contrat";
import { EtatValidationPlanning } from "../models/enums/etatValidationPlanningEnum";
import { CreateDemandeModificationModel, CreateDuplicationSalarieModel } from "../models/creneaux/creneau";
import { EtatDemandeModification } from "../models/enums/etatDemandeModification";

const resource = "Utilisateurs";

export default class UtilisateursRessource {
  public async paginatedList(
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    hiddenUser?: boolean,
    orderBy?: string,
    orderByDescending?: boolean,
    fonctionCode?: string,
    entrepriseId?: string
  ): Promise<PaginatedList<DetailsUtilisateur>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (hiddenUser) params.append("HiddenUser", hiddenUser.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());
    if (fonctionCode && fonctionCode.length)
      params.append("FonctionCode", fonctionCode?.toString());
    if (entrepriseId) params.append("entrepriseId", entrepriseId.toString());
   

    const response = await api.get(`${resource}`, { params: params });
    return response.data;
  }

  public async getById(id: string): Promise<DetailsUtilisateur> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params });
    return response.data;
  }

  public async removeAccount(id: string): Promise<DetailsUtilisateur> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async askResetPassword(data: DetailsUtilisateur): Promise<string> {
    const response = await api.post(`${resource}/${data.id}/askResetPassword`, {
      id: data.id,
    });
    return response.data;
  }

  public async resetPassword(
    data: UtilisateurValidatePasswordModel
  ): Promise<string> {
    const response = await api.post(
      `${resource}/${data.userId}/resetPassword/${data.passwordToken}`,
      { password: data.password }
    );
    return response.data;
  }

  public async confirmAccount(
    data: UtilisateurValidateAccountModel
  ): Promise<string> {
    const response = await api.post(
      `${resource}/${data.userId}/confirmAccount`,
      data
    );
    return response.data;
  }

  public async askConfirmEmailManually(data: DetailsUtilisateur): Promise<string> {
    const response = await api.post(
      `${resource}/${data.id}/resendConfirmationLink`,
      {
        id: data.id,
      }
    );
    return response.data;
  }

  public async askConfirmEmail(
    data: UtilisateurValidateEmailModel
  ): Promise<string> {
    const response = await api.get(
      `${resource}/${data.userId}/confirmEmail/${data.emailToken}`
    );
    return response.data;
  }

  public async confirmEmail(data: DetailsUtilisateur): Promise<string> {
    const response = await api.post(`${resource}/${data.id}/confirmEmail/`, {
      id: data.id,
    });
    return response.data;
  }

  public async post(data: UtilisateurCreateModel): Promise<string> {
    const response = await api.post(`${resource}`, data);
    return response.data;
  }

  public async delete(id: string): Promise<string> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async update(id: string, data: DetailsUtilisateur): Promise<string> {
    let formData = new FormData();
    data.fichiersCompetencesAdditionnelles?.forEach((file: File) =>
      formData.append("fichiersCompetencesAdditionnelles", file)
    );
    if (data.accesRessifnetASST) {
      formData.append(
        "accesRessifnetASST",
        data.accesRessifnetASST
          ? data.accesRessifnetASST.toString()
          : false.toString()
      );
    }
    if (data.accesRessifnetFASTT) {
      formData.append(
        "accesRessifnetFASTT",
        data.accesRessifnetFASTT
          ? data.accesRessifnetFASTT.toString()
          : false.toString()
      );
    }
    console.log(data)
    //RLA
    if (data.accesRessifnetFASTT) {
      formData.append(
        "accesRessifnetFASTT",
        data.accesRessifnetFASTT
          ? data.accesRessifnetFASTT.toString()
          : false.toString()
      );
    }
    if (data.accesConsigneRessifnetFASTT) {
      formData.append(
        "accesConsigneRessifnetFASTT",
        data.accesConsigneRessifnetFASTT
          ? data.accesConsigneRessifnetFASTT.toString()
          : false.toString()
      );
    }
    if (data.accesRessifnetFASTTParams) {
      formData.append(
        "accesRessifnetFASTTParams",
        data.accesRessifnetFASTTParams ? 
        data.accesRessifnetFASTTParams.toString()
        : false.toString()
      );
    }
    if (data.listRessifnetFASTTFonction) {
      data.listRessifnetFASTTFonction?.forEach((id: number) =>
        formData.append("listRessifnetFASTTFonction", id.toString())
      );
    }
    
    if (data.accesRessifnetIST) {
      formData.append(
        "accesRessifnetIST",
        data.accesRessifnetIST
          ? data.accesRessifnetIST.toString()
          : false.toString()
      );
    }
    if (data.id) {
      formData.append("id", data.id ? data.id.toString() : false.toString());
    }
    if (data.telephone) {
      formData.append(
        "telephone",
        data.telephone ? data.telephone.toString() : false.toString()
      );
    }

    if (data.emailConfirmed) {
      formData.append(
        "emailConfirmed",
        data.emailConfirmed ? data.emailConfirmed.toString() : false.toString()
      );
    }

    if (data.utilisateurMasquee) {
      formData.append(
        "utilisateurMasquee",
        data.utilisateurMasquee
          ? data.utilisateurMasquee.toString()
          : false.toString()
      );
    }
    if (data.isPrivateMember) {
      formData.append(
        "isPrivateMember",
        data.isPrivateMember
          ? data.isPrivateMember.toString()
          : false.toString()
      );
    }

    if (data.email) {
      formData.append(
        "email",
        data.email ? data.email.toString() : false.toString()
      );
    }

    formData.append(
      "nomutilisateur",
      data.email ? data.email.toString() : false.toString()
    );

    if (data.nom) {
      formData.append("nom", data.nom ? data.nom.toString() : false.toString());
    }
    if (data.prenom) {
      formData.append(
        "prenom",
        data.prenom ? data.prenom.toString() : false.toString()
      );
    }
    if (data.telephone) {
      formData.append(
        "email",
        data.email ? data.email.toString() : false.toString()
      );
    }
    if (data.voitureFonction) {
      formData.append(
        "voitureFonction",
        data.voitureFonction
          ? data.voitureFonction.toString()
          : false.toString()
      );
    }
    if (data.matricule) {
      formData.append(
        "matricule",
        data.matricule
          ? data.matricule.toString()
          : false.toString()
      );
    }
    if (data.emailProfessionnel) {
      formData.append(
        "emailProfessionnel",
        data.emailProfessionnel
          ? data.emailProfessionnel.toString()
          : false.toString()
      );
    }
    // if (data.materielMisADispo) {
    //   formData.append(
    //     "materielMisADispo",
    //     data.materielMisADispo
    //       ? data.materielMisADispo.toString()
    //       : false.toString()
    //   );
    // }
    if (data.voitureSociete) {
      formData.append(
        "voitureSociete",
        data.voitureSociete ? data.voitureSociete.toString() : false.toString()
      );
    }
    if (data.matricule) {
      formData.append(
        "matricule",
        data.matricule ? data.matricule.toString() : false.toString()
      );
    }
    if (data.competencesAdditionnelles) {
      formData.append(
        "competencesAdditionnelles",
        data.competencesAdditionnelles
          ? data.competencesAdditionnelles.toString()
          : false.toString()
      );
    }
    if (data.telephoneMAD) {
      formData.append(
        "telephoneMAD",
        data.telephoneMAD
          ? data.telephoneMAD.toString()
          : false.toString()
      );
    }
    if (data.ordinateur) {
      formData.append(
        "ordinateur",
        data.ordinateur
          ? data.ordinateur.toString()
          : false.toString()
      );
    }
    if (data.autreMateriel) {
      formData.append(
        "autreMateriel",
        data.autreMateriel
          ? data.autreMateriel.toString()
          : false.toString()
      );
    }

    const response = await api.put(`${resource}/${id}`, formData);
    return response.data;
  } 

  public async updatePhoto(id: string, photo: File) {
    let formData = new FormData();
    formData.append("photo", photo);
    const response = await api.put(`${resource}/${id}/photo`, formData);
    return response.data;
  }

  public async updateAcces(id: string, data: DetailsUtilisateur): Promise<string> {
    const response = await api.put(`${resource}/${id}/applications`, data);
    return response.data;
  }

  public async getProfilsByUserId(id: string): Promise<Profil[]> {
    const response = await api.get(`${resource}/${id}/Profils`);
    return response.data;
  }

  public async getPlanningByUserId(id: string): Promise<Planning> {
    const response = await api.get(`${resource}/${id}/Plannings`);
    return response.data;
  }

  public async getGroupesByUserId(id: string): Promise<Planning> {
    const response = await api.get(`${resource}/${id}/Groupes`);
    return response.data;
  }

  public async getEquipesByUserId(id: string): Promise<Planning> {
    const response = await api.get(`${resource}/${id}/Equipes`);
    return response.data;
  }

  public async getCompetencesByUserId(id: string): Promise<Planning> {
    const response = await api.get(`${resource}/${id}/Competences`);
    return response.data;
  }

  public async addFonction(id: string): Promise<string> {
    const response = await api.delete(`${resource}/${id}`);
    return response.data;
  }

  public async getListContratsByUtilisateurId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string
  ): Promise<PaginatedList<Contrat>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());

    const response = await api.get(`${resource}/${id}/contrats`, {
      params: params,
    });
    return response.data;
  }

  public async getContratById(id: string, contratId: string): Promise<Contrat> {
    const response = await api.get(`${resource}/${id}/contrats/${contratId}`);
    return response.data;
  }

  public async createContratOnUtilisateur(
    id: string,
    data: ContratCreateModel
  ): Promise<Contrat> {
    const response = await api.post(`${resource}/${id}/contrats`, data);
    return response.data;
  }

  public async updateContratUtilisateur(
    id: string,
    contratId: string,
    data: ContratEditModel
  ): Promise<Contrat> {
    const response = await api.put(`${resource}/${id}/contrats/${contratId}`, data);
    return response.data;
  }

  public async deleteContratUtilisateur(
    id: string,
    contratId: string
  ): Promise<Contrat> {
    const response = await api.delete(
      `${resource}/${id}/contrats/${contratId}`
    );
    return response.data;
  }

  public async getInfosSalariePlanning(
    id: string,
    startDate: string
  ): Promise<InfosSalariePlanning>
  {
    const params = new URLSearchParams();
    if (startDate) params.append('StartDate', startDate.toString());

    const response = await api.get(`${resource}/${id}/InfosSalariePlanning`, { params: params } )
    return response.data
  }

  public async updateValidationPlanning(
    utilisateurId: string,
    moisAnnee: string,
    etat: EtatValidationPlanning
  ): Promise<any>
  {
    const data = {
      utilisateurId: utilisateurId,
      moisAnnee: moisAnnee,
      etat: etat
    }

    const response = await api.put(`${resource}/${utilisateurId}/validationsPlannings/${moisAnnee}`, data);
    return response.data;
  }

  public async createDemandeModification(
    utilisateurId: string,
    demandeModification: CreateDemandeModificationModel
  ): Promise<any>
  {

    const response = await api.post(`${resource}/${utilisateurId}/demandesModificationsPlannings`, demandeModification);
    return response.data;
  }

  public async listDemandesModifications(
    utilisateurId: string
  ): Promise<any>
  {
    const response = await api.get(`${resource}/${utilisateurId}/demandesModificationsPlannings`);

    return response.data;
  }

  public async patchDemandeModification(utilisateurId: string, demandeModificationId: string, etat: EtatDemandeModification) {
    const data = {
      utilisateurId: utilisateurId,
      demandeModificationPlanningId: demandeModificationId,
      etat: etat
    }

    const response = await api.patch(`${resource}/${utilisateurId}/demandesModificationsPlannings/${demandeModificationId}`, data);
    return response.data;
  }
}
