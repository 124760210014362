


























import {
  BSidebar,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { required, min, max } from "@validations";
import {
  CreateInformationAdditionnelle,
  InformationAdditionnelle,
} from "@/api/models/informationAdditionnelle/informationAdditionnelle";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { IconesEnum } from "@/api/models/enums/iconesEnum";
import { CouleursEnum } from "@/api/models/enums/couleursEnum";
import { VueSelect } from "vue-select";
import { Parametre } from "@/api/models/parametres/parametre";
import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";
import { Groupe } from "@/api/models/groupes/groupe";

@Component({
  components: {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueSelect,
  },
  name: "preview-rigths-information-additionnelle",
})
export default class PreviewRightsInformationAdditionnelleModal extends Vue {
  @Prop({ required: true }) informationAdditionnelleId!: any;
  @Prop({ required: true }) previewRights!: any;
  @Prop({
    required: false,
    default: "Modification d'une information additionnelle",
  })
  title!: string;
  loading = true;

  required = required;
  min = min;
  max = max;

  searchQuery = "";
  totalCount = 0;
  searchEntitesItems: any[] = [];

  entitiesOptions = Object.values(TypeEntiteEnum);

  access = Object.entries(this.previewRights);

  couleurSelected: string = "";
  iconeSelected: string = "";

  listFonctions: { text: string; value: string }[] = [];
  listGroupes: { text: string; value: string }[] = [];

  informationAdditionnelle: InformationAdditionnelle | null = null;

  test() {
    this.informationAdditionnelle = null;
  }

  async onModalDisplay() {
    console.log(this.previewRights);
    this.loading = false;
  }
}
