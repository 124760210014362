export default [
  {
    path: '/utilisateurs',
    name: 'utilisateurs',
    component: () => import('@/views/utilisateurs/Index.vue'),
    meta: {
      pageTitle: 'Gestion des utilisateurs',
      breadcrumb: [
        {
          text: 'Gestion des utilisateurs',
          active: true,
        },
      ],
      rights: [
        'UTILISATEURS:read'
      ]
    },
  },
  {
    path: '/utilisateurs/edit/:id',
    name: 'edit-utilisateurs',
    component: () => import('@/views/utilisateurs/Edit.vue'),
    meta: {
      pageTitle: 'Gestion des utilisateurs',
      breadcrumb: [
        {
          text: 'Gestion des utilisateurs',
          active: true,
        },
        {
          text: 'Modification'
        }
      ],
      rights: [
        'UTILISATEURS:read'
      ]
    },
  }
]
  