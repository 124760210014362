export default [
  {
    path: '/fournisseurs',
    name: 'fournisseurs',
    component: () => import('@/views/fournisseurs/Index.vue'),
    meta: {
      pageTitle: 'Gestion des fournisseurs',
      breadcrumb: [
        {
          text: 'Gestion des fournisseurs',
          active: true,
        },
      ],
      rights: [
        'FOURNISSEURS:read'
      ]
    },
  },
  {
    path: '/fournisseurs/details/:id',
    name: 'details-fournisseur',
    component: () => import('@/views/fournisseurs/Details.vue'),
    meta: {
      pageTitle: 'Détails des fournisseurs',
      breadcrumb: [
        {
          text: 'Détails des fournisseurs',
          active: true,
        },
        {
          text: 'Détails'
        }
      ],
      rights: [
        'FOURNISSEURS:read'
      ]
    },
  }
]