import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { Groupe } from '../models/groupes/groupe';
import { MembreGroupes } from '../models/membreGroupes/membreGroupes';


const resource = 'Groupes'

export default class GroupesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Groupe>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Groupe> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: Groupe) : Promise<Groupe> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Groupe) : Promise<Groupe> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Groupe> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async listMembres( id : string, pagenumber?: number, pagesize?: number ) : Promise<PaginatedList<MembreGroupes>>{
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    
    const response = await api.get(`${resource}/${id}/membres`, {params: params})
    return response.data
  }

  public async addMembre(groupeId: string , membreId : string) : Promise<Groupe>{
    var data = {
      groupeId: groupeId,
      membreId: membreId,
    };
    const response = await api.post(`${resource}/${groupeId}/membres`, data);
    return response.data;
  }

  public async removeMembre( groupeId: string , membreId : string ): Promise<Groupe> {
    const response = await api.delete(`${resource}/${groupeId}/membres/${membreId}`);
    return response.data;
  }


}
