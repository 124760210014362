import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { ExportDonnee, ExportDonneeCreateModel } from '../models/exportDonnees/export'

const resource = 'ExportDonnees'

export default class ExportDonneesResource {
    public async paginatedList(pagenumber?: number, pagesize?: number) : Promise<PaginatedList<ExportDonnee>> {
        const params = new URLSearchParams();
        if (pagenumber) params.append('PageNumber', pagenumber.toString());
        if (pagesize) params.append('PageSize', pagesize.toString()); 
    
        const response = await api.get(`${resource}`, { params: params } )
        return response.data
      }
    
      public async getById(id: string) : Promise<ExportDonnee> {
        const params = new URLSearchParams();
    
        const response = await api.get(`${resource}/${id}`, { params: params })
        return response.data
      }

      public async find(id: string) : Promise<ExportDonnee> {
        const response = await api.get(`${resource}/${id}/find`,   {
            responseType: "arraybuffer",
            timeout: 120000,
          } )
        return response.data
      }
    
      public async post(data: ExportDonnee) : Promise<ExportDonneeCreateModel> {
        const response = await api.post(`${resource}`, data)
        return response.data
      }
    
      public async update(id: string, data : ExportDonnee) : Promise<ExportDonnee> {
        const response = await api.put(`${resource}/${id}`, data)
        return response.data
      }
    
      public async delete(id : string) : Promise<ExportDonnee> {
        const response = await api.delete(`${resource}/${id}`)
        return response.data
      }
}