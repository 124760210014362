import api from '@/libs/axios'
import { ActionCollective, CreateActionCollectiveModel } from '../models/actionsCollectives/actionCollective';
import { InterventionCollective } from '../models/actionsCollectives/interventionCollective';
import { PaginatedList } from '../models/common/paginatedList'

const baseResource = 'Entreprises'
const resource = 'ActionsCollectives'

export default class ActionsCollectivesResource {
  public async paginatedList(entrepriseId: string, pagenumber?: number, pagesize?: number): Promise<PaginatedList<ActionCollective>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());

    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}`, { params: params })
    return response.data
  }

  appendArray(form_data: any, values: any, name: any){
    if (!values && name)
      form_data.append(name, '');
    else
    {
        if (typeof values == 'object'){
          for(let key in values){
            if(typeof values[key] == 'object')
              this.appendArray(form_data, values[key], name + '[' + key + ']');
            else
              form_data.append(name + '[' + key + ']', values[key]);
          }
        }
        else
            form_data.append(name, values);
    }

    return form_data;
}

  public async create(entrepriseId: string, data: CreateActionCollectiveModel, config: any): Promise<string> {
    let formData = new FormData();

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    data.interventions?.forEach((intervention: InterventionCollective, index) => {
      this.appendArray(formData, intervention, `interventions[${index}]`)
    })
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.dateAction) formData.append('dateAction', data.dateAction)
    
    if (data.branchesId) {
      data.branchesId.forEach((brancheId: string) => {
        formData.append('branchesId', brancheId)
      })
    }

    if (data.sitesId) {
      data.sitesId.forEach((siteId: string) => {
        formData.append('sitesId', siteId)
      })
    }

    if (data.regionsId) {
      data.regionsId.forEach((regionId: string) => {
        formData.append('regionsId', regionId)
      })
    }

    if (data.servicesId) {
      data.servicesId.forEach((serviceId: string) => {
        formData.append('servicesId', serviceId)
      })
    }

    const response = await api.post(`${baseResource}/${entrepriseId}/${resource}`, formData, config)
    return response.data
  }

  public async delete(entrepriseId: string, id: string) : Promise<ActionCollective> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async getById(entrepriseId: string, id: string) {
    const response = await api.get(`${baseResource}/${entrepriseId}/${resource}/${id}`)
    return response.data
  }

  public async put(entrepriseId: string, id: string, data: CreateActionCollectiveModel, config: any): Promise<string> {
    let formData = new FormData();
    formData.append('id', id)

    data.fichiers?.forEach((file: File) => formData.append('fichiers', file));
    data.interventions?.forEach((intervention: InterventionCollective, index) => {
      this.appendArray(formData, intervention, `interventions[${index}]`)
    })
    if (data.commentaire) formData.append('commentaire', data.commentaire)
    if (data.dateAction) formData.append('dateAction', data.dateAction)

    if (data.branchesId) {
      data.branchesId.forEach((brancheId: string) => {
        formData.append('branchesId', brancheId)
      })
    }

    if (data.sitesId) {
      data.sitesId.forEach((siteId: string) => {
        formData.append('sitesId', siteId)
      })
    }
    if (data.regionsId) {
      data.regionsId.forEach((regionId: string) => {
        formData.append('regionsId', regionId)
      })
    }
    if (data.servicesId) {
      data.servicesId.forEach((serviceId: string) => {
        formData.append('servicesId', serviceId)
      })
    }

    const response = await api.put(`${baseResource}/${entrepriseId}/${resource}/${id}`, formData, config)
    return response.data
  }

  public async deleteFile(entrepriseId: string, id: string, fileId: string) : Promise<ActionCollective> {
    const response = await api.delete(`${baseResource}/${entrepriseId}/${resource}/${id}/Files/${fileId}`)
    return response.data
  }
}
