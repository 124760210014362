export default [
    {
      path: "/trombinoscope",
      name: "trombinoscope",
      component: () => import('@/views/trombinoscope/Index.vue'),
      meta: {
        pageTitle: 'Trombinoscope',
        breadcrumb: [
          {
            text: 'Trombinoscope',
            active: true,
          },
        ],
        rights: [
          'UTILISATEURS:read'
        ]
      }
    },
]