import api from '@/libs/axios'
import { PaginatedList } from '@/api/models/common/paginatedList'
import { SecteurActivite } from '@/api/models/options/secteursActivites/secteurActivite';

const baseUrl = "ressifnet"
const resource = 'SecteursActivite'

export default class RessifnetSecteursActivitesResource {
  public async paginatedList(pagenumber?: number, pagesize?: number, search?: string) : Promise<PaginatedList<SecteurActivite>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString()); 

    const response = await api.get(`${baseUrl}/${resource}`, { params: params } )
    return response.data
  }
}
