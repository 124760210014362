import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Profil } from '../models/authorization/profils/profil'

const resource = 'Profils'
const baseResource = 'Identities'

export default class ProfilsResource {
  public async paginatedList(userId: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Profil>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString()); 
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}/${userId}`, { params: params } )
    return response.data
  }

  public async deleteProfilIdentity(identityId : string, profilId : string) : Promise<Profil> {
    const response = await api.delete(`${baseResource}/${identityId}/${resource}/${profilId}`)
    return response.data
  }

  public async post(data : Profil) : Promise<Profil> {
    const response = await api.post(`${baseResource}/${data.identityId}/${resource}`, data)
    return response.data
  }

  public async postDroitProfil(id: string, droitId: string, identityId: string) : Promise<Profil> {
    const params = {
      id: id,
      droitId: droitId
    };
    const response = await api.post(`${baseResource}/${identityId}/${resource}/${id}/Droits/${droitId}`, params)
    return response.data
  }

  public async deleteDroitProfil(id: string, droitId: string, identityId: string) : Promise<Profil> {
    const response = await api.delete(`${baseResource}/${identityId}/${resource}/${id}/Droits/${droitId}`)
    return response.data
  }

  public async get(identityId: string, profileId: string) : Promise<Profil> { 
    const response = await api.get(`${baseResource}/${identityId}/${resource}/${profileId}` )
    return response.data
  }

  public async postProfilEntreprise(identityId: string, profilId: string, profilEntreprise: any) {
    const params = {
      identityId: identityId,
      profilId: profilId,
      entrepriseId: profilEntreprise.entrepriseId,
      brancheId: profilEntreprise.brancheId,
      regionId: profilEntreprise.regionId,
      siteId: profilEntreprise.siteId
    }

    const response = await api.post(`${baseResource}/${identityId}/${resource}/${profilId}/Entreprises/${profilEntreprise.entrepriseId}`, params)

    return response.data
  }

  public async deleteProfilEntreprise(id: string, profilEntrepriseId: string, identityId: string) : Promise<Profil> {
    const response = await api.delete(`${baseResource}/${identityId}/${resource}/${id}/Entreprises/${profilEntrepriseId}`)
    return response.data
  }
}
