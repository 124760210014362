import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList';
import { BIRapport } from '../models/biRapport/biRapport';

const resource = 'BIRapports'

export default class BIRapporsResource {
  public async paginatedList() : Promise<PaginatedList<BIRapport>> {
    const params = new URLSearchParams();
    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
