import api from '@/libs/axios'
import { Feeling } from '../models/feeling/feeling'

const resource = 'Humeur'


export default class UtilisateursRessource {

public async post(data : Feeling) : Promise<string> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async getFeeling() : Promise<Feeling> {
    const response = await api.get(`${resource}`)
    return response.data
  }
}