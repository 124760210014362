import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Structure } from '../models/structures/structure';
import { Agence, AgenceCreateModel } from '../models/structures/agence';


const resource = 'structures'

export default class StructuresResource {
  public async paginatedList(search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Structure>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Structure> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: Structure) : Promise<Structure> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Structure) : Promise<Structure> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Structure> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }

  public async getListAgencesByStructureId(
    id: string,
    pagenumber?: number,
    pagesize?: number,
    search?: string,
    orderBy?: string,
    orderByDescending?: boolean
  ): Promise<PaginatedList<Agence>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append("PageNumber", pagenumber.toString());
    if (pagesize) params.append("PageSize", pagesize.toString());
    if (search && search.length) params.append("Search", search?.toString());
    if (orderBy) params.append("OrderBy", orderBy.toString());
    if (orderByDescending)
      params.append("OrderByDescending", orderByDescending.toString());

    const response = await api.get(`${resource}/${id}/agences`, {
      params: params,
    });
    return response.data;
  }

  public async getAgenceById(id: string, agenceId: string): Promise<Agence> {
    const response = await api.get(`${resource}/${id}/agences/${agenceId}`);
    return response.data;
  }

  public async createAgenceOnStructure(
    id: string,
    data: AgenceCreateModel
  ): Promise<Agence> {
    const response = await api.post(`${resource}/${id}/agences`, data);
    return response.data;
  }

  public async updateAgenceStructure(
    id: string,
    agenceId: string,
    data: Agence
  ): Promise<Agence> {
    const response = await api.put(
      `${resource}/${id}/agences/${agenceId}`,
      data
    );
    return response.data;
  }

  public async deleteAgenceStructure(
    structureId: string,
    agenceId: string
  ): Promise<Agence> {
    const response = await api.delete(
      `${resource}/${structureId}/agences/${agenceId}`
    );
    return response.data;
  }
}
