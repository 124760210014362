import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { ChangelogType } from '../models/changelogs/changelogType';


const resource = 'changelogTypes'

export default class ChangelogTypesResource {
  public async paginatedList(search?: string, pagenumber?: number, pagesize?: number) : Promise<PaginatedList<ChangelogType>> {
    const params = new URLSearchParams();
    if (search) params.append('Search', search.toString());
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<ChangelogType> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data: ChangelogType) : Promise<ChangelogType> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : ChangelogType) : Promise<ChangelogType> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<ChangelogType> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
}
