import { render, staticRenderFns } from "./CreateBasicModal.vue?vue&type=template&id=632ce037&scoped=true&"
import script from "./CreateBasicModal.vue?vue&type=script&lang=ts&"
export * from "./CreateBasicModal.vue?vue&type=script&lang=ts&"
import style0 from "./CreateBasicModal.vue?vue&type=style&index=0&id=632ce037&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "632ce037",
  null
  
)

export default component.exports