import api from '@/libs/axios'
import { Avis } from '../models/avis/avis'
import { PaginatedList } from '../models/common/paginatedList';

const resource = 'Avis'


export default class AvisRessource {

  public async paginatedList(pagenumber?: number, pagesize?: number, orderBy?: string, orderByDescending?: boolean, search?: string) : Promise<PaginatedList<Avis>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 
    if (search && search.length) params.append('Search', search?.toString());
    if (orderBy) params.append('OrderBy', orderBy.toString());
    if (orderByDescending) params.append('OrderByDescending', orderByDescending.toString());

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

public async updateAvis(data : Avis) : Promise<string> {
    const response = await api.put(`${resource}`, data)
    return response.data
  }
}