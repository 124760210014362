export default [
  {
    path: '/adherents',
    name: 'adherents',
    component: () => import('@/views/adherents/Index.vue'),
    meta: {
      pageTitle: 'Gestion des adhérents',
      breadcrumb: [
        {
          text: 'Gestion des adhérents',
          active: true,
        },
      ],
      rights: [
        'ENTREPRISES:read',
        'EXTERNES_ENTREPRISES_OPENSOME:read'
      ]
    },
  },
  {
    path: '/adherents/details/:id',
    name: 'details-adherent',
    component: () => import('@/views/adherents/Details.vue'),
    meta: {
      pageTitle: 'Gestion des adhérents',
      breadcrumb: [
        {
          text: 'Gestion des adhérents',
          active: true,
        },
        {
          text: 'Modification'
        }
      ],
      rights: [
        'ENTREPRISES:read',
        'EXTERNES_ENTREPRISES_OPENSOME:read'
      ]
    },
  },
  {
    path: '/adherents/modification/:id',
    name: 'modification-adherent',
    component: () => import('@/views/adherents/Modification.vue'),
    meta: {
      pageTitle: 'Modification de l\'adhérent',
      breadcrumb: [
        {
          text: 'Gestion de l\'adhérent',
          active: true,
        },
        {
          text: 'Modification'
        }
      ],
      rights: [
        'ENTREPRISES:update'
      ]
    },
  }
]
  