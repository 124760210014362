















































































































































































































































































































import {
  BSidebar,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { required, min, max } from "@validations";
import {
  CreateInformationAdditionnelle,
  InformationAdditionnellePreview,
} from "@/api/models/informationAdditionnelle/informationAdditionnelle";
import { Entreprise } from "@/api/models/adherents/adherent";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { Partenaire } from "@/api/models/partenaires/partenaire";
import { Fournisseur } from "@/api/models/fournisseurs/fournisseur";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Fonction } from "@/api/models/authorization/fonctions/fonction";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { IconesEnum } from "@/api/models/enums/iconesEnum";
import { CouleursEnum } from "@/api/models/enums/couleursEnum";
import { VueSelect } from "vue-select";
import { Parametre } from "@/api/models/parametres/parametre";
import { TypeEntiteEnum } from "@/api/models/enums/typeEntiteEnum";
import { Groupe } from "@/api/models/groupes/groupe";
import PreviewRightsInformationAdditionnelleModal from "./PreviewRightsModal.vue";
@Component({
  components: {
    BSidebar,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    SearchableVueSelect,
    VueSelect,
    BFormFile,
    BFormSelect,
    BFormSelectOption,
    PreviewRightsInformationAdditionnelleModal,
  },
  name: "create-information-additionnelle",
})
export default class CreateInformationAdditionnelleModal extends Vue {
  @Prop({ required: false, default: "Ajout d'une information additionnelle" })
  @Prop({ required: true, type: Boolean })
  title!: string;
  loading = false;

  required = required;
  min = min;
  max = max;

  searchQuery = "";
  totalCount = 0;
  searchEntitesItems: any[] = [];
  previewRightsValue = [];
  entitiesOptions = TypeEntiteEnum;

  icones = Object.values(IconesEnum);
  iconesEnum = IconesEnum;

  couleurs = Object.values(CouleursEnum);
  couleursEnum = CouleursEnum;

  couleurSelected: string = "";
  iconeSelected: string = "";

  listFonctions: { text: string; value: string }[] = [];
  listGroupes: { text: string; value: string }[] = [];

  previewInformationAdditionelle: InformationAdditionnellePreview = {};
  newInformationAdditionnelle: CreateInformationAdditionnelle = {
    fichiers: [],
  };

  previewsAccess = {};

  getIconeKey(iconeValue: any) {
    return Object.keys(this.iconesEnum)[
      Object.values(this.iconesEnum).indexOf(iconeValue)
    ];
  }

  getCouleurKey(couleurValue: any) {
    return Object.keys(this.couleursEnum)[
      Object.values(this.couleursEnum).indexOf(couleurValue)
    ];
  }

  @Watch("newInformationAdditionnelle.cibleType")
  async cibleTypeChanged() {
    this.searchEntitesItems = [];
    this.searchEntites({});
  }

  async previewRights() {
    this.previewInformationAdditionelle.cibleId =
      this.newInformationAdditionnelle.cibleId;
    this.previewInformationAdditionelle.cibleType =
      this.newInformationAdditionnelle.cibleType;
    this.previewInformationAdditionelle.groupesAllowed =
      this.newInformationAdditionnelle.groupesAllowed;
    this.previewInformationAdditionelle.fonctionsAllowed =
      this.newInformationAdditionnelle.fonctionsAllowed;

    await this.$http.myressif.informationsAdditionnelles
      .previewRights(this.previewInformationAdditionelle)
      .then(
        async (response: any) => {
          this.previewsAccess = Object.entries(response);
          this.$bvModal.show("modal-preview-right");
        
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async searchEntites(params: any) {
    if (!params || params?.reset) {
      this.totalCount = 0;
      this.searchEntitesItems = this.searchEntitesItems?.filter(e => e.id == this.newInformationAdditionnelle.cibleId);
    }

    switch (this.newInformationAdditionnelle.cibleType) {
      case TypeEntiteEnum.UTILISATEUR:
        await this.$http.myressif.utilisateurs
          .paginatedList(
            params?.pageNumber ?? 1,
            params?.pageSize ?? 10,
            params?.search ?? ""
          )
          .then((response: PaginatedList<DetailsUtilisateur>) => {
            this.searchEntitesItems = this.searchEntitesItems!.concat(
              response.items.filter(
                (ri) => !this.searchEntitesItems!.some((lsi) => lsi.id == ri.id)
              )
            ).map(x => {
              x.nomComplet = `${x.nom} ${x.prenom}`
              return x;
            });

            this.totalCount = response.totalCount;
          });
        break;
      case TypeEntiteEnum.ENTREPRISE:
        await this.$http.myressif.entreprises
          .paginatedList(
            params?.pageNumber ?? 1,
            params?.pageSize ?? 10,
            params?.orderBy,
            params?.orderByDescending,
            params?.search ?? ""
          )
          .then((response: PaginatedList<Entreprise>) => {
            this.totalCount = response.totalCount;
            this.searchEntitesItems = this.searchEntitesItems!.concat(response.items
              .map(({ libelle: nom, id: id }) => ({
                nom,
                id,
              }))
              .filter(
                (ri) => !this.searchEntitesItems!.some((lsi) => lsi.id == ri.id)
              )).map(x => {
                x.nomComplet = x.nom
                return x;
              });
          });
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        await this.$http.myressif.fournisseurs
          .paginatedList(
            params?.pageNumber ?? 1,
            params?.pageSize ?? 10,
            params?.orderBy,
            params?.orderByDescending,
            params?.search ?? ""
          )
          .then((response: PaginatedList<Fournisseur>) => {
            this.searchEntitesItems = this.searchEntitesItems!.concat(
              response.items.filter(
                (ri) => !this.searchEntitesItems!.some((lsi) => lsi.id == ri.id)
              )
            ).map(x => {
                x.nomComplet = x.nom
                return x;
              });
            this.totalCount = response.totalCount;
          });
        break;
      case TypeEntiteEnum.PARTENAIRE:
        await this.$http.myressif.partenaires
          .paginatedList(
            params?.pageNumber ?? 1,
            params?.pageSize ?? 10,
            params?.orderBy,
            params?.orderByDescending,
            params?.search ?? ""
          )
          .then((response: PaginatedList<Partenaire>) => {
            this.searchEntitesItems = this.searchEntitesItems!.concat(
              response.items.filter(
                (ri) => !this.searchEntitesItems!.some((lsi) => lsi.id == ri.id)
              )
            ).map(x => {
                x.nomComplet = x.nom
                return x;
              });
            this.totalCount = response.totalCount;
          });
        break;
      default:
        break;
    }
  }

  @Watch("newInformationAdditionnelle.cibleType")
  async loadFonctions() {
    var parametreNom = "";
    switch (this.newInformationAdditionnelle.cibleType) {
      case TypeEntiteEnum.UTILISATEUR:
        parametreNom = "informationsadditionnelles_droits_utilisateur";
        break;
      case TypeEntiteEnum.ENTREPRISE:
        parametreNom = "informationsadditionnelles_droits_adherent";
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        parametreNom = "informationsadditionnelles_droits_fournisseur";
        break;
      case TypeEntiteEnum.PARTENAIRE:
        parametreNom = "informationsadditionnelles_droits_partenaire";
      default:
        break;
    }

    var toRemove: string[] = [];
    var only: string[] = [];

    await this.$http.myressif.parametres
      .getByNom(parametreNom)
      .then(async (response: Parametre) => {
        var valeur = JSON.parse(response.valeur);
        toRemove = valeur["remove"];
        only = valeur["only"];
        await this.$http.myressif.fonctions
        .paginatedList(1, 100)
        .then((response: PaginatedList<Fonction>) => {
          var filtered = response.items.filter(
            (f) =>
              (only?.length ? only.includes(f.code) : true) &&
              (toRemove?.length ? !toRemove.includes(f.code) : true)
          );
          this.listFonctions = filtered.map(({ libelle: text, id: value }) => ({
            text,
            value,
          }));
        });
      });

    
  }

  @Watch("newInformationAdditionnelle.cibleType")
  async loadGroupes() {
    var parametreNom = "";
    switch (this.newInformationAdditionnelle.cibleType) {
      case TypeEntiteEnum.UTILISATEUR:
        parametreNom = "informationsadditionnelles_groupes_utilisateur";
        break;
      case TypeEntiteEnum.ENTREPRISE:
        parametreNom = "informationsadditionnelles_groupes_adherent";
        break;
      case TypeEntiteEnum.FOURNISSEUR:
        parametreNom = "informationsadditionnelles_groupes_fournisseur";
        break;
      case TypeEntiteEnum.PARTENAIRE:
        parametreNom = "informationsadditionnelles_groupes_partenaire";
      default:
        break;
    }

    var toRemove: string[] = [];
    var only: string[] = [];

    await this.$http.myressif.parametres
      .getByNom(parametreNom)
      .then(async (response: Parametre) => {
        var valeur = response ? JSON.parse(response.valeur) : [];
        toRemove = valeur["remove"];
        only = valeur["only"];
        await this.$http.myressif.groupes
        .paginatedList(1, 100)
        .then((response: PaginatedList<Groupe>) => {
          var filtered = response.items.filter(
            (g) =>
              (only?.length ? only.includes(g.id) : true) &&
              (toRemove?.length ? !toRemove.includes(g.id) : true)
          );
          this.listGroupes = filtered.map(({ libelle: text, id: value }) => ({
            text,
            value,
          }));
        });
      });    
  }

  async submit() {
    this.loading = true;

    await this.$http.myressif.informationsAdditionnelles
      .post(this.newInformationAdditionnelle)
      .then(
        async (response: any) => {
          this.$emit("info-added");
          successAlert.fire({
            timer: 3500,
            title: "Ajout d'une information additionnelle",
            text: "Information ajoutée avec succès",
          });
          this.newInformationAdditionnelle = {};
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        this.loading = false;
      });
  }
}
