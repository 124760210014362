import api from '@/libs/axios'
import { TypeInterventionCollective } from '../models/actionsCollectives/typeIntervention';
import { PaginatedList } from '../models/common/paginatedList'

const resource = 'TypesInterventionCollective'

export default class TypesInterventionCollectiveRessource {
  public async paginatedList(pagenumber?: number, pagesize?: number, actifOnly?: boolean): Promise<PaginatedList<TypeInterventionCollective>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString());
    if (actifOnly) params.append('ActifOnly', actifOnly.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }
}
