import api from '@/libs/axios'


const resource = 'UnreadChangelogs'

export default class UnreadChangelogsResource {
  public async countUnread(application: string, tenantId: string) : Promise<number> {
    const params = new URLSearchParams();
    if (application) params.append('Application', application.toString()); 
    if (tenantId) params.append('TenantId', tenantId.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async post(application: string, tenantId: string) : Promise<string> {
    const response = await api.post(`${resource}`, {application: application, tenantId: tenantId})
    return response.data
  }
}
