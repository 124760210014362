import api from '@/libs/axios'
import { PaginatedList } from '../models/common/paginatedList'
import { Contact } from '../models/contacts/contact';

const resource = 'Contacts'

export default class ContactsRessources {
  public async paginatedList(pagenumber?: number, pagesize?: number) : Promise<PaginatedList<Contact>> {
    const params = new URLSearchParams();
    if (pagenumber) params.append('PageNumber', pagenumber.toString());
    if (pagesize) params.append('PageSize', pagesize.toString()); 

    const response = await api.get(`${resource}`, { params: params } )
    return response.data
  }

  public async getById(id: string) : Promise<Contact> {
    const params = new URLSearchParams();

    const response = await api.get(`${resource}/${id}`, { params: params })
    return response.data
  }

  public async post(data : Contact) : Promise<Contact> {
    const response = await api.post(`${resource}`, data)
    return response.data
  }

  public async update(id: string, data : Contact) : Promise<Contact> {
    const response = await api.put(`${resource}/${id}`, data)
    return response.data
  }

  public async delete(id : string) : Promise<Contact> {
    const response = await api.delete(`${resource}/${id}`)
    return response.data
  }
}